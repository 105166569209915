import React from 'react';
import { useSignupContext } from '../../../hooks';
import { Alert, Popup, Button } from '../../../components';

const RequirementPopup = ({ setOpenPopup }) => {
  const {
    signupState,
    clientInfo: { useRemita, clientName },
  } = useSignupContext();
  const { locationError } = signupState;
  return (
    <Popup
      title={
        locationError
          ? 'We could not get your location'
          : 'Minimum requirements for a loan'
      }
      classes="loan-requirements"
    >
      {locationError ? (
        <>
          <Alert classes="error">
            Sorry, we could not access your browser location, please check your
            browser settings and grant access to location.
          </Alert>
        </>
      ) : (
        <>
          <p>
            You will need the following in order to successfully get your loan
          </p>
          <ul>
            <li>Be at least 18 years of age</li>
            <li>A valid BVN</li>
            <li>A personal email account</li>
            <li>A personal bank account</li>
            <li>A Debit Card with at least 3 months Validity</li>
          </ul>

          <section className="consent-message">
            {useRemita ? (
              <p>
                By clicking on Continue, I consent to {clientName} obtaining
                information from relevant third parties as may be necessary, on
                my personal details, identification details, employment details,
                financial details, loan details and other related details
                including my Bank Verification Number (Data), to make a decision
                on my loan application. I also consent to the scheduled payments
                and loan amount to be deducted from my salary at source before
                credit to any of my bank accounts. I further consent that any
                outstanding loans shall be recovered automatically from any bank
                accounts linked to my Data, whether or not disclosed in my loan
                application, in the case of default.
              </p>
            ) : (
              <p>
                By clicking on Continue, I consent to {clientName} obtaining
                information from relevant third parties as may be necessary to
                make a decision on my loan application.
              </p>
            )}
          </section>

          <div className="button-list center-text">
            <Button
              click_event={() => {
                setOpenPopup(false);
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </Popup>
  );
};

export default RequirementPopup;
