import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  DataCard,
  Loader,
  OfferLetter,
  RepaymentCard,
  ReviewFeedback,
  Tag,
  TransactionTable,
} from '../../components';
import { NairaAmount, NewLoanIcon, SadSmiley } from '../../components/Icon';
import ProdCampNPSWidget from '../../components/ProdCampNPSWidget/ProdCampNPSWidget';
import RecovaMandateBanner from '../../components/RecovaMandateBanner/RecovaMandateBanner';
import { RemitaMandateBanner } from '../../components/RemitaMandateBanner';
import { logEvent } from '../../lib/GAHelper';
import {
  MANDATE_TYPES,
  PENDING_DISBURSEMENT_STATUSES,
  getErrorMessages,
  removeUnderscore,
} from '../../lib/utils';
import authService from '../../services/authService';
import { ViewerProvider } from '../providers';
import './_Dashboard.scss';
import VerifyBvnBanner from '../../components/VerifyBvnBanner/VerifyBvnBanner';
import LidyaMandateBanner from '../../components/LidyaMandateBanner/LidyaMandateBanner';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      emailConfirmationMessage: '',
      errors: {
        emailConfirmation: '',
      },
    };

    this.triggerEmailConfirmation = this.triggerEmailConfirmation.bind(this);
  }

  async triggerEmailConfirmation() {
    logEvent('Dashboard', 'Resend Verification Email', true);
    this.setState({ loading: true });

    const response = await authService.triggerEmailConfirmation();
    const error = 'Unable to send verification email.';
    this.setState({ loading: false });

    if (response == null || typeof response === 'undefined') {
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    if (response.data.errors) {
      const error = getErrorMessages(response.data.errors);
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    const { ok } = response.data.data.triggerEmailConfirmation;

    if (!ok) {
      this.setState({ errors: { emailConfirmation: error } });
      return;
    }

    const confirmEmailNotification =
      'A verification link has been sent to your email. Please, click on the link to verify your email.';

    this.setState({ emailConfirmationMessage: confirmEmailNotification });
  }

  render() {
    return (
      <Fragment>
        <ViewerProvider
          // eslint-disable-next-line react/no-children-prop
          children={({
            currentLoan,
            applications,
            latestLoan,
            user,
            refetch,
            portfolios,
          }) => {
            const currentLoanRepayment =
              (currentLoan.portfolioNumber &&
                currentLoan.repayments.find(
                  repayment => repayment.status.name === 'PENDING',
                )) ||
              {};

            const pendingDebitMandate = portfolios?.find(
              portfolio =>
                PENDING_DISBURSEMENT_STATUSES.includes(portfolio.status.name) &&
                portfolio?.debitMandateMeta?.status?.name ===
                  'PENDING_ACTIVATION',
            );

            const activePortfolio = portfolios.find(
              portfolio => portfolio && portfolio.status.name !== 'CLOSED',
            );

            return applications.length !== 0 ? (
              <Fragment>
                <section className={'container mandate'}>
                  <RemitaMandateBanner
                    activePortfolio={activePortfolio}
                    refetch={refetch}
                  />

                  {pendingDebitMandate?.debitMandateMeta?.type?.name ===
                    MANDATE_TYPES.RECOVA && (
                    <RecovaMandateBanner
                      debitMandate={pendingDebitMandate?.debitMandateMeta}
                    />
                  )}

                  {pendingDebitMandate?.debitMandateMeta?.type?.name ===
                    MANDATE_TYPES.LIDYA && (
                    <LidyaMandateBanner
                      debitMandate={pendingDebitMandate?.debitMandateMeta}
                    />
                  )}
                </section>

                {!user?.isBvnVerified && (
                  <section className={'container'}>
                    <VerifyBvnBanner />
                  </section>
                )}

                {this.state.loading && <Loader />}
                <section className={'container'}>
                  {this.state?.emailConfirmationMessage ? (
                    <Alert classes="success">
                      {this.state?.emailConfirmationMessage}
                    </Alert>
                  ) : !user?.isEmailConfirmed ? (
                    <Alert classes="warning">
                      <span>
                        Your loans will not be disbursed until you have verified
                        your email. A verification link has been sent to{' '}
                        {user?.email}!, please click on the link to verify your
                        email.
                      </span>
                      <span>
                        <Button
                          click_event={this.triggerEmailConfirmation}
                          classes="resend-vericode"
                          button_text="Resend verification code"
                        />
                      </span>
                    </Alert>
                  ) : this.state?.errors?.emailConfirmation ? (
                    <Alert classes="warning">
                      {this.state?.errors?.emailConfirmation}!{' '}
                      <Button
                        click_event={this.triggerEmailConfirmation}
                        classes="resend-vericode"
                        button_text="Retry"
                      />
                    </Alert>
                  ) : (
                    ''
                  )}

                  {currentLoan?.id ||
                  latestLoan?.status?.name === 'UNDER_REVIEW' ||
                  latestLoan?.status?.name === 'PENDING' ? (
                    ''
                  ) : (
                    <Link
                      to="/application/new"
                      className="button icon-left visible-mobile"
                    >
                      <span className="icon">
                        <NewLoanIcon />
                      </span>
                      <span className={'nav-text'}>New Loan</span>
                    </Link>
                  )}

                  <OfferLetter application={latestLoan} refetch={refetch} />

                  {(latestLoan?.status?.name === 'UNDER_REVIEW' ||
                    latestLoan?.status?.name === 'PENDING') && (
                    <DataCard>
                      <RepaymentCard
                        amountPaid="0.00"
                        fullAmount={latestLoan?.fullAmount}
                        percent="0"
                        repaymentAmount="n/a"
                        repaymentDate={latestLoan?.dateOfRepayment}
                        overlay={true}
                        status={removeUnderscore(latestLoan?.status?.name)}
                      />
                    </DataCard>
                  )}

                  {currentLoan?.id && (
                    <DataCard>
                      <RepaymentCard
                        application={currentLoan}
                        amountPaid={currentLoan.amountPaid}
                        fullAmount={currentLoan.fullAmount}
                        percent={currentLoan.percent}
                        repaymentAmount={currentLoan.fullAmount}
                        repaymentDate={
                          currentLoanRepayment.dueDate ||
                          currentLoan.dateOfRepayment
                        }
                        portfolioNumber={currentLoan.portfolioNumber}
                        status={removeUnderscore(currentLoan.status.name)}
                      />
                    </DataCard>
                  )}

                  <DataCard>
                    <TransactionTable
                      txTableHeaders={[
                        'Date',
                        'Status',
                        'Loan ID',
                        'Loan Amount',
                        'Repayment Amount',
                        'Repayment Date',
                      ]}
                    >
                      {applications.map((data, index) => (
                        <li key={index}>
                          <div
                            className="tx-column transaction-date"
                            data-header="Date"
                          >
                            {moment(data.createdAt).format('ll')}
                          </div>
                          <div className="tx-column" data-header="Status">
                            <Tag
                              status={
                                data.portfolio
                                  ? data.portfolio.status.name.toLowerCase() ===
                                    'closed'
                                    ? 'paid'
                                    : data.portfolio.status.name.toLowerCase() ===
                                      'disbursed'
                                    ? 'active'
                                    : data.portfolio.status.name.toLowerCase() ===
                                      'pending_disbursement'
                                    ? 'approved'
                                    : removeUnderscore(
                                        data.portfolio.status.name,
                                      ).toLowerCase()
                                  : removeUnderscore(
                                      data.status.name,
                                    ).toLowerCase()
                              }
                            />
                          </div>
                          <div
                            className="tx-column loan-id"
                            data-header="Loan ID"
                          >
                            <span>
                              {data.portfolio
                                ? data.portfolio.portfolioNumber
                                : data.applicationNumber}
                            </span>
                          </div>
                          <div
                            className="tx-column full"
                            data-header="Loan Amount"
                          >
                            <NairaAmount amount={data.amount} />
                          </div>
                          <div
                            className="tx-column"
                            data-header="Repayment Amount"
                          >
                            <NairaAmount
                              amount={
                                data?.portfolio
                                  ? data?.portfolio?.fullAmount
                                  : data?.fullAmount
                              }
                            />
                          </div>
                          <div
                            className="tx-column"
                            data-header="Repayment Date"
                          >
                            <span>
                              {moment(data.dateOfRepayment).format('ll')}
                            </span>
                          </div>
                        </li>
                      ))}
                    </TransactionTable>
                  </DataCard>

                  <ReviewFeedback application={latestLoan} refetch={refetch} />
                </section>
              </Fragment>
            ) : (
              <section className="container no-loan">
                {!user?.isBvnVerified && (
                  <section className={'container'}>
                    <VerifyBvnBanner />
                  </section>
                )}
                <DataCard>
                  <div className="smiley-holder">
                    <SadSmiley />
                  </div>
                  <h3 className="center-text">You have no active loan</h3>
                  <Link to="/application/new" className="button">
                    Apply for a new loan
                  </Link>
                </DataCard>
              </section>
            );
          }}
        />

        <ProdCampNPSWidget />
      </Fragment>
    );
  }
}

export default Dashboard;
