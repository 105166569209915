import React, { useEffect } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { NEW_LOAN_ROUTES } from '../LoanApplication/routes';
import { ApplicationProvider } from './ApplicationContext';
import ApplicationRoute from './ApplicationRoute';
import './_Application.scss';
import {
  FailurePage,
  LoanPending,
  RefereeDetails,
  GovernmentIDCard,
  WorkIdUploadScreen,
  BankStatementUpload,
  ConfirmRequest,
  EmploymentLetterUpload,
  SocialMedia,
  OkraPage,
  UploadSelfie,
  SupportingDocuments,
  UtilityBillsUpload,
  WorkEmailVerification,
  AddCard,
  BankAccount,
  HomeAddressScreen,
  EmploymentDetailsScreen,
} from '../LoanApplication';
import { useLoanApplicationContext } from '../../hooks';
import LoaderPage from '../LoanApplication/LoaderPage';
import { useSelector } from 'react-redux';
import { contextState } from '../../redux/application/contextStateRedux';
import { useHistory } from 'react-router';
import MbsOtp from '../LoanApplication/Mbs/components/MbsOtp';
import MbsInstruction from '../LoanApplication/Mbs/components/MbsInstruction';
import { useCustomForm } from './CustomFormApplication/useCustomForm';

const ApplicationRouter = () => {
  const { path } = useRouteMatch();
  const getPath = route => `${path}/${route}`;
  const history = useHistory();
  const {
    params: { applicationNumber },
  } = useRouteMatch();

  const { isLoaded, initRequiredStepsState, fetchNextStep, categoryId } =
    useLoanApplicationContext();

  const steps = useSelector(contextState);

  const { applicationForm } = useCustomForm();

  useEffect(() => {
    if (isLoaded) {
      initRequiredStepsState();
    }
  }, [isLoaded, categoryId]);

  useEffect(() => {
    const applicationSteps = steps.find(
      a => a.applicationNumber === applicationNumber,
    );
    if (Array.isArray(applicationForm) && applicationForm?.length > 0) {
      history.push(`/application-custom/${applicationNumber}`);
    } else if (applicationSteps) {
      const nextPage = fetchNextStep(applicationSteps.steps);
      history.push(`/application/${applicationNumber}/${nextPage}`);
    }
  }, [steps, JSON.stringify(applicationForm)]);

  return (
    <ApplicationProvider>
      <section className="container application">
        <Switch>
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.uploadBankStatement)}
            exact
            component={BankStatementUpload}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.pending)}
            exact
            component={LoanPending}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.referee)}
            exact
            component={RefereeDetails}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.idUpload)}
            exact
            component={GovernmentIDCard}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.workIdUpload)}
            exact
            component={WorkIdUploadScreen}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.failure)}
            exact
            component={FailurePage}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.confirmLoan)}
            exact
            component={ConfirmRequest}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.employmentLetterUpload)}
            exact
            component={EmploymentLetterUpload}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.socialMedia)}
            exact
            component={SocialMedia}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsOtp)}
            component={MbsOtp}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsInstruction)}
            component={MbsInstruction}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.okra)}
            exact
            component={OkraPage}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.passportUpload)}
            exact
            component={UploadSelfie}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.supportingDocuments)}
            exact
            component={SupportingDocuments}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.utilityBillUpload)}
            exact
            component={UtilityBillsUpload}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.verifyWorkEmail)}
            exact
            component={WorkEmailVerification}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.addCard)}
            exact
            component={AddCard}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.bankAccount)}
            exact
            component={BankAccount}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.homeAddress)}
            exact
            component={HomeAddressScreen}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.employmentDetails)}
            exact
            component={EmploymentDetailsScreen}
          />
          <ApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.loader)}
            exact
            component={LoaderPage}
          />
        </Switch>
      </section>
    </ApplicationProvider>
  );
};

const Application = () => {
  return (
    <ApplicationProvider>
      <ApplicationRouter />
    </ApplicationProvider>
  );
};

export default Application;
