export const APPLICATION_STEPS = {
  // Assign a bank account to an application
  setBankAccount: {
    name: 'SET_APPLICATION_BANK_ACCOUNT',
    displayMessage: 'Assign a bank account to application',
    requiresManualInput: true,
  },
  // Start MBS request - if application bank is supported by request mbs API
  initiateBankStatementRequest: {
    name: 'INITIATE_BANK_STATEMENT_REQUEST',
    displayMessage: 'Initiate Bank Statement request',
    requiresManualInput: false,
  },
  // Complete MBS request - if application bank is supported by request mbs API
  completeBankStatementRequest: {
    name: 'COMPLETE_BANK_STATEMENT_REQUEST',
    displayMessage: 'Complete Bank Statement Request',
    requiresManualInput: true,
  },
  // Fetch Custom Model Result
  generateCreditScore: {
    name: 'GENERATE_CREDIT_SCORE',
    displayMessage: 'Get Custom Model Result',
    requiresManualInput: false,
  },
  // Fetch Decide Result
  getDecideResult: {
    name: 'GET_DECIDE_RESULT',
    displayMessage: 'Get Bank statement analysis from Decide',
    requiresManualInput: false,
  },
  // Fetch Credit Bureau Report - if required by client
  getCreditBureauReport: {
    name: 'GET_CREDIT_BUREAU_REPORT',
    displayMessage: 'Fetch Credit Bureau Report',
    requiresManualInput: false,
  },
  // Complete MBS request - if application bank is not supported by request mbs API i.e (Branch/E-channels initiated)
  completeExternalBankStatementRequest: {
    name: 'COMPLETE_EXTERNAL_BANK_STATEMENT_REQUEST',
    displayMessage: 'Complete Bank Statement Request',
    requiresManualInput: true,
  },
  getCreditRegistryReport: {
    name: 'GET_CREDIT_REGISTRY_REPORT',
    displayMessage: 'Fetch Credit Registry Report',
    requiresManualInput: false,
  },
  getCrcReport: {
    name: 'GET_CRC_REPORT',
    displayMessage: 'Fetch CRC Report',
    requiresManualInput: false,
  },
  getCrcMicroReport: {
    name: 'GET_CRC_MICRO_REPORT',
    displayMessage: 'Fetch CRC Micro Report',
    requiresManualInput: false,
  },
  getOkraTransactions: {
    name: 'GET_OKRA_TRANSACTIONS',
    displayMessage: 'Fetch Okra Transactions',
  },
  uploadBankStatement: {
    name: 'UPLOAD_BANK_STATEMENT',
    displayMessage: 'Upload Bank Statement',
    requiresManualInput: true,
  },
};
