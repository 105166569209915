import React from 'react';
import { useSignupContext } from '../../hooks';
import { VFDBvnVerification } from './components/VfdBvnValidator/VfdBvnValidationForm';
import { IdentityPassBvnVerification } from './components/IdentityPassBvnValidator/IdentityPassBvnValidationForm';

export const BvnValidator = ({
  setVerifiedUserData,
  setVerificationFormState,
  setUserHasVerifiedBvn,
  verificationFormState,
  updateBvnVerificationState,
  setUpdateBvnVerificationState,
}) => {
  const {
    clientInfo: { bvnServiceProviders },
  } = useSignupContext();

  const VFDIsEnabled =
    bvnServiceProviders?.find(provider => provider?.name === 'VFD')?.enabled ===
    true;

  const IdentityPassIsEnabled =
    bvnServiceProviders?.find(provider => provider?.name === 'IDENTITY_PASS')
      ?.enabled === true;

  return (
    <>
      {/* Note: The bvnServiceProviders null check here should be only on IdentityPassVerification 
    as this is the fallback bvn verification provider if no provider has been set */}

      {(bvnServiceProviders === null || IdentityPassIsEnabled) && (
        <IdentityPassBvnVerification
          setUserHasVerifiedBvn={setUserHasVerifiedBvn}
          setVerifiedUserData={setVerifiedUserData}
          setVerificationFormState={setVerificationFormState}
          updateBvnVerificationState={updateBvnVerificationState}
          setUpdateBvnVerificationState={setUpdateBvnVerificationState}
        />
      )}

      {VFDIsEnabled && (
        <VFDBvnVerification
          setUserHasVerifiedBvn={setUserHasVerifiedBvn}
          setVerifiedUserData={setVerifiedUserData}
          setVerificationFormState={setVerificationFormState}
          verificationFormState={verificationFormState}
          updateBvnVerificationState={updateBvnVerificationState}
          setUpdateBvnVerificationState={setUpdateBvnVerificationState}
        />
      )}
    </>
  );
};
