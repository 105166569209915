import React from 'react';
import { Link } from 'react-router-dom';
import {
  Alert,
  Button,
  DataCard,
  Input,
  PasswordInput,
} from '../../components';
import { LoginProvider } from '../providers';
import './_SignIn.scss';

const SignIn = () => (
  <LoginProvider>
    {({ onSubmit, loading, errorMessage, successMessage, form }) => (
      <section className="container sign-in">
        <DataCard title="Sign in to your account">
          <form onSubmit={onSubmit}>
            {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
            {successMessage && (
              <Alert classes="success">{successMessage}</Alert>
            )}
            <Input
              type="email"
              data-testid="email-field"
              placeholder="Email Address"
              name="email"
              autoFocus
              classes={`border-bottom ${
                form.email.input.errorMessage ? 'error' : ''
              }`}
              {...form.email.input}
            />
            <PasswordInput
              data-testid="password-field"
              placeholder="Password"
              name="password"
              classes={`border-bottom ${
                form.password.input.errorMessage ? 'error' : ''
              }`}
              {...form.password.input}
            />
            <Button
              data-testid="signin-button"
              type="submit"
              classes={`center ${loading ? 'loading' : ''}`}
            >
              Sign In
            </Button>
            <div className="create-account">
              Don't have an account? <Link to="/sign-up">Sign Up</Link>
            </div>
            <div className="create-account">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </form>
        </DataCard>
      </section>
    )}
  </LoginProvider>
);

export default SignIn;
