import styled from 'styled-components';
import { Wrapper as Wrap } from '../styled';
export { Title, Subtitle, Aside, NextButton, PreviousButton } from '../styled';

export const Name = styled.section`
  background-color: ${({ theme }) => theme.colors.color_green}12;
  border: 1px solid ${({ theme }) => theme.colors.color_green};
  border-radius: 0.4rem;
  color: ${({ theme }) => theme.colors.text_color};
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin: 3rem 0 0;
  padding: 1rem 2rem;
`;

export const Wrapper = styled(Wrap)`
  .data-card {
    margin: 5rem auto;
    padding: 6.4rem 4rem;
    max-width: ${({ accountLength }) =>
      accountLength > 1 ? '70rem' : '60rem'};
  }
  .bank-card {
    width: 30rem;

    .bank-name {
      float: right;
      font-size: 1.4rem;
    }

    .account-number {
      font-size: 1.5rem;
      font-weight: ${({ theme }) => theme.fonts.font_semibold};
      line-height: 2.8rem;
    }
  }
`;

export const ButtonsGroup = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  gap: 20px;
  margin-top: 20px;
`;

export const LinkButton = styled.button`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 1.3rem;
  padding: 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  margin: 0;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
`;

export const AddNewBankAccountButton = styled.button`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 1.3rem;
  padding: 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  margin: 10px 0 0;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
`;

export const UploadStatementButton = styled.button`
  background-color: ${({ theme }) => theme.colors.color_white};
  color: ${({ theme }) => theme.secondaryColor};
  display: block;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;
  margin: 0 auto;
  padding: 0 25px;
`;

export const ContinueButton = styled.button`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  display: block;
  flex: 1;
  width: 100%;
  max-width: 350px;
  padding: 7px;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;
  margin: 0 auto;
`;

export const BankAccountsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Detail = styled.label`
  border: 1px solid #a4a59a;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
  padding: 7px 12px;
  transition: 0.3s;
  width: 100%;

  &.inactive {
    display: none;
  }
`;

export const BankDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RadioDescription = styled.div`
  li {
    list-style: none;
    line-height: 20px;
  }
`;

export const RadioTitle = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  line-height: 2rem;
  width: 100%;
`;

export const RadioInputWrapper = styled.section`
  align-items: center;
  position: relative;
  display: flex;
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme }) => theme.secondaryColor};
    border: 0;

    :after {
      background: white;
    }
  }
`;

export const Span = styled.span`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #a4a59a;
  transition: 0.3s;

  :after {
    content: '';
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
    transition: max-height 1s;
  }
`;

export const AddBankAccount = styled.section`
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;

  &.active {
    max-height: 700px;
  }
`;

export const AddBankAccountButton = styled.button`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  display: block;
  flex: 1;
  padding: 2px 15px;
  border-radius: 0.4rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;
  margin: 3rem 0 0;

  &:disabled {
    opacity: 0.7;
  }
`;
