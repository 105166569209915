import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authGetter } from '../../lib/utils';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authCreds = authGetter();

  return (
    <Route
      {...rest}
      render={props =>
        authCreds.hasApiKey && !authCreds.isTempApiKey ? (
          <Fragment>
            <Component {...props} />
          </Fragment>
        ) : authCreds.hasApiKey && authCreds.isTempApiKey ? (
          <Redirect
            to={{
              pathname: 'sign-up',
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: 'sign-in',
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
