import { useImageUploader, useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { useCallback, useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';

const useGovernmentIdCardForm = () => {
  const {
    user,
    applicationLoading,
    setLoanApplicationState,
    createApplicationTrace,
    goToNextPage,
    setCompletedStep,
  } = useLoanApplicationContext();

  const {
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
  } = useImageUploader();
  const [governmentIdDetails, setGovernmentIdDetails] = useState({});

  const type = 'GOVERNMENTID';

  const handleUpload = useCallback(
    async ({ documentName, documentNo }) => {
      logEvent('upload-id', 'Upload government id');

      setLoanApplicationState(prevState => ({
        ...prevState,
        documentName,
        documentNo,
      }));
      const { firstName, lastName, id } = user;
      const fileName = `${documentName}_${documentNo}_${firstName}_${lastName}_${id}`;

      uploadImage(governmentIdDetails.fileDetails, fileName, type);
    },
    [governmentIdDetails, user],
  );

  const handlePreview = e => {
    handleImagePreview(e, setGovernmentIdDetails);
  };

  useEffect(() => {
    if (data) {
      const {
        uploadImageAndSaveToUserMetaData: { status },
      } = data;

      if (status) {
        setCompletedStep('govtId');
        goToNextPage();
      }
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.governmentId,
      'Navigated to Government ID Upload Screen',
    );
  }, []);

  return {
    applicationLoading,
    uploadImageLoading,
    governmentIdDetails,
    uploadImageError,
    handlePreview,
    handleUpload,
  };
};
export default useGovernmentIdCardForm;
