import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as S from './styled';
import { Alert } from '../../../../../components';
import { useGetCategories } from '../../../../../hooks';
import { replaceTideSymbol } from '../../../../../lib/utils';
import { NairaAmount } from '../../../../../components/Icon';

const LoanProductScreen = ({
  setIsLoading,
  setShowAmountField,
  showAmountField,
  applicationState,
  setApplicationState,
  setActiveScreen,
  isActive,
}) => {
  const { loanCategories, loading, errorMessage, isSuccess } =
    useGetCategories();

  useEffect(() => {
    if (isSuccess) {
      if (!loanCategories || loanCategories?.length === 0) {
        setShowAmountField(true);
        setActiveScreen('loan-amount');
      }
    }
  }, [loanCategories, isSuccess]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleProductChange = value => {
    setApplicationState({
      loanCategoryId: value,
      loanAmount: null,
      loanDuration: null,
    });

    setShowAmountField(true);
    setActiveScreen('loan-amount');
  };

  const handleSubmit = () => {};

  if (
    loading ||
    (isSuccess && !loanCategories) ||
    (isSuccess && loanCategories?.length === 0)
  )
    return <></>;

  return (
    <section>
      <S.Title>Select a Loan Product</S.Title>
      {errorMessage && <Alert classes="error">{errorMessage}</Alert>}
      <S.Subtitle>
        Please select a loan product below to continue your application
      </S.Subtitle>
      {loanCategories?.length > 0 && (
        <Formik
          initialValues={{
            loanProduct: '',
          }}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {() => (
            <Form>
              <S.LoanCategoryWrapper>
                {loanCategories?.map((category, categoryIndex) => (
                  <LoanCategoryItem
                    key={`product_${categoryIndex}`}
                    category={category}
                    handleProductChange={handleProductChange}
                    applicationState={applicationState}
                    isActive={isActive}
                  />
                ))}
              </S.LoanCategoryWrapper>

              {applicationState.loanCategoryId && (
                <>
                  {showAmountField ? (
                    <S.LinkButton
                      type="button"
                      onClick={() => {
                        setShowAmountField(false);
                        setActiveScreen('product-category');
                        setApplicationState(prevState => ({
                          ...prevState,
                          loanAmount: null,
                          loanDuration: null,
                        }));
                      }}
                    >
                      Change Loan Product
                    </S.LinkButton>
                  ) : (
                    <S.LinkButton
                      type="button"
                      onClick={() => {
                        setShowAmountField(true);
                        setActiveScreen('loan-amount');
                      }}
                    >
                      Cancel Change
                    </S.LinkButton>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </section>
  );
};

export default LoanProductScreen;

const LoanCategoryItem = ({
  category,
  handleProductChange,
  applicationState,
  isActive,
}) => {
  return (
    <S.Detail
      className={
        !isActive
          ? applicationState?.loanCategoryId === category.id
            ? 'active'
            : 'inactive'
          : ''
      }
    >
      <S.CategoryDetail>
        <S.RadioTitle>{category.name}</S.RadioTitle>

        <S.RadioDescription>
          {category?.description &&
            replaceTideSymbol(category.description).map((item, key) => (
              <li key={key}>{item.trim()}</li>
            ))}
          Min: <NairaAmount amount={category?.minAmount} />
          <br />
          Max: <NairaAmount amount={category?.maxAmount} />
        </S.RadioDescription>
      </S.CategoryDetail>

      <Field name="loanProduct">
        {({ field, meta }) => (
          <LoanCategoryRadio
            field={field}
            meta={meta}
            value={category.id}
            handleProductChange={handleProductChange}
          />
        )}
      </Field>
    </S.Detail>
  );
};

const LoanCategoryRadio = ({ value, field, handleProductChange }) => {
  return (
    <>
      <S.RadioInputWrapper>
        <S.RadioInput
          type="radio"
          name="radio"
          value={value}
          onChange={e => {
            field.onChange(e);
            handleProductChange(e?.target?.value);
          }}
        />
        <S.Span />
      </S.RadioInputWrapper>
    </>
  );
};
