import { Component } from 'react';
import { UserService } from '../../services';
import { notify } from 'react-notify-toast';
import { getErrorMessages } from '../../lib/utils';
import Constants from '../../lib/constants';
import { authGetter } from '../../lib/utils';

const { status, duration } = Constants;

class LoggedInUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      status: '',
      isEmailConfirmed: '',
      isPhoneConfirmed: '',
      portfolios: [],
      user: {},
      account: {},
      currentLoan: {},
    };
    this.loadUserDetails = this.loadUserDetails.bind(this);
    this.checkUserLoggedIn = this.checkUserLoggedIn.bind(this);
    this.redirectToSignIn = this.redirectToSignIn.bind(this);
    this.assignResponseToState = this.assignResponseToState.bind(this);
    this.getCurrentLoan = this.getCurrentLoan.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.checkUserLoggedIn();
    this.loadUserDetails();
    this.setState({ isLoading: false });
  }

  async loadUserDetails() {
    const response = await UserService.viewer();

    if (
      response == null ||
      typeof response === 'undefined' ||
      !response.data ||
      typeof response.data === 'undefined'
    ) {
      this.redirectToSignIn(
        'Invalid response from server.',
        status.ERROR,
        duration.SHORT,
      );
      return;
    }

    if (response.data.errors || typeof response.data.errors !== 'undefined') {
      const error = getErrorMessages(response.data.errors);
      this.redirectToSignIn(error, status.ERROR, duration.LONG);
      return;
    }

    if (response.data.viewer || typeof response.data.viewer !== 'undefined') {
      this.redirectToSignIn(
        'There was an issue getting your data. Kindly re-login to proceed',
        status.ERROR,
        duration.LONG,
      );
      return;
    }

    this.assignResponseToState(response.data.data.viewer.me, 'user');
    this.assignResponseToState(response.data.data.viewer.account, 'account');
    this.getCurrentLoan();
    this.setState({ isLoading: false });
  }

  async checkUserLoggedIn() {
    const apiKey = authGetter();

    if (!apiKey.hasApiKey) {
      this.redirectToSignIn(
        'Your session has expired. Kindly re-login to proceed',
        status.ERROR,
        duration.LONG,
      );
    }
  }

  async redirectToSignIn(message, type, duration) {
    notify.show(message, type, duration);
    localStorage.clear();
    this.props.history.push('/sign-in');
  }

  async assignResponseToState(response, stateKey) {
    let keys = Object.keys(response);
    for (const key of keys) {
      this.state[stateKey][key] = response[key];
    }
  }

  async getCurrentLoan() {
    if (
      this.state.isLoading === true ||
      typeof this.state.account.portfolios === 'undefined' ||
      this.state.account.portfolios.nodes === 'undefined'
    ) {
      return (this.state.currentLoan = false);
    }

    for (const loan of this.state.account.portfolios.nodes) {
      if (loan.status.name === 'DISBURSED') {
        const percent =
          ((loan.fullAmount - loan.amountPaid) / loan.fullAmount) * 100 - 100;
        this.state.currentLoan = { ...loan, percent };

        return this.state.currentLoan;
      }
    }
  }
}

export default LoggedInUser;
