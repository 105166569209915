import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { Provider } from 'react-redux';
import config from './config/config';
import { App } from './containers/';
import ApolloWrapper from './lib/ApolloWrapper';
import { datadogRum } from '@datadog/browser-rum';

if (config.web.app_env === 'production') {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: `DynamicCp_${config.web.app_env}`,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
  });

  datadogRum.init({
    applicationId: 'b5e15547-1cb8-4627-ae4d-8e70a7723763',
    clientToken: 'pub8172d4ef5a9b955b4077016f7280cd98',
    site: 'datadoghq.eu',
    service: 'customer-portal-v1',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: config.web.version,
    env: `DynamicCp_${config.web.app_env}`,
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
  <Provider store={store}>
    <ApolloWrapper>
      <App />
    </ApolloWrapper>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const configuration = {
  onUpdate: registration => {
    if (registration && registration.waiting) {
      if (
        window.confirm('New version available! Refresh to update your app?')
      ) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
