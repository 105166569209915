// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useLoanApplicationContext } from '../../../hooks';
import freeEmailDomains from 'personal-email-domains';
import { pages } from '../../../lib/constants';

const useEmploymentDetailsForm = () => {
  const {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    loanApplicationIsLoading,
    createApplicationTrace,
    indexSlug,
    clientInfo: { verifyWorkEmail },
    goToPreviousPage,
    goToNextPage,
    user,
    userLoading,
    setCompletedStep,
  } = useLoanApplicationContext();

  const [currentEmploymentDetails, setCurrentEmploymentDetails] = useState({});

  const addEmploymentDetails = values => {
    const workAddress = values?.employerAddress + ' ' + values?.employerState;

    const employmentDetails = {
      ...values,
      workAddress,
    };

    setLoanApplicationState(prevState => ({
      ...prevState,
      employmentDetails,
    }));
    updateUserDetails({ ...employmentDetails }, () => {
      setCompletedStep('employmentDetails');
      goToNextPage();
    });
  };

  const checkWorkEmailValidation = values => {
    const errors = {};
    const splitEmail = values.workEmail ? values.workEmail.split('@') : '';
    const blacklistedDomain = freeEmailDomains.includes(splitEmail[1]);
    if (blacklistedDomain) {
      errors.workEmail =
        'Your work email is not from a supported email provider. Please enter another work email';
    }
    return errors;
  };

  useEffect(() => {
    createApplicationTrace(
      pages.employmentDetails,
      'Navigated to Employment Details Screen',
    );
  }, []);

  useEffect(() => {
    if (!userLoading) {
      const { userMetadata } = user || {};
      setCurrentEmploymentDetails({
        address: userMetadata?.find(meta => meta.name === 'address')?.value,
        state: userMetadata?.find(meta => meta.name === 'state')?.value,
        localGovernment: userMetadata?.find(
          meta => meta.name === 'localGovernment',
        )?.value,
      });
    }
  }, [user, userLoading]);

  return {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    loanApplicationIsLoading,
    createApplicationTrace,
    indexSlug,
    clientInfo: { verifyWorkEmail },
    currentEmploymentDetails,
    addEmploymentDetails,
    goToPreviousPage,
    userLoading,
    checkWorkEmailValidation,
  };
};

export default useEmploymentDetailsForm;
