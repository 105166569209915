import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useLoanApplicationContext } from '../../../hooks';
import Constants from '../../../lib/constants';
import { useMutation } from '@apollo/client';
import {
  COMPLETE_BANK_STATEMENT_REQUEST,
  EXTERNAL_BANK_STATEMENT_REQUEST,
  INITIATE_BANK_STATEMENT_REQUEST,
} from '../mutations';
import { handleGraphQLErrors } from '../../../lib/utils';
import { notify } from 'react-notify-toast';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { NEW_LOAN_ROUTES } from '../routes';

const useMbsForm = ({ customForm, handleNext, formIndex }) => {
  const customMbsDates = useMemo(
    () => ({
      startDate: moment().subtract(1, 'day').subtract(6, 'months').format('ll'),
      endDate: moment().subtract(1, 'day').format('ll'),
    }),
    [],
  );

  const history = useHistory();
  const {
    params: { applicationNumber },
  } = useRouteMatch();

  const {
    updateContextState,
    createApplicationTrace,
    showErrorAlert,
    updateAuthType,
    clientInfo: { clientName, requiresBankStatementUpload },
    goToPreviousPage,
    goToNextPage,
    application,
    account,
    applicationLoading,
    setCompletedStep,
  } = useLoanApplicationContext();
  const applicationId = application?.application?.id;
  const bankId = account?.bankAccounts[0]?.bank?.id;
  const bankName = account?.bankAccounts[0]?.bank?.name;
  const { status, duration, pages } = Constants;
  const [isFromExternal, setIsFromExternal] = useState(false);

  const [runCompleteBankStatementRequest, { loading }] = useMutation(
    isFromExternal
      ? EXTERNAL_BANK_STATEMENT_REQUEST
      : COMPLETE_BANK_STATEMENT_REQUEST,
    {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) || 'Error getting bank statement',
        );
      },
      onCompleted({
        completeBankStatementRequest,
        completeExternalBankStatementRequest,
      }) {
        if (
          completeBankStatementRequest?.success ||
          completeExternalBankStatementRequest?.success
        ) {
          if (!customForm) {
            setCompletedStep('mbs');
            goToNextPage();
          }
        } else {
          updateAuthType();
          showErrorAlert('Error completing bank statement request');
        }
      },
    },
  );

  const [runInitiateBankStatementRequest] = useMutation(
    INITIATE_BANK_STATEMENT_REQUEST,
    {
      onError(error) {
        updateContextState('bank-details', {
          initiateBankStatementError: {
            message: error.message,
            stack: error.stack,
            error,
          },
        });
        createApplicationTrace(pages.mbsBankDetails, error.message, true, {
          initiateBankStatementError: {
            message: error.message,
            stack: error.stack,
            error,
          },
        });
        const errorMessage = handleGraphQLErrors(error);
        showErrorAlert(
          errorMessage || 'Error initiating bank statement request',
        );
        // todo: Set Upload bank statement to true
        // goToNextPage();
      },
      onCompleted({ initiateBankStatementRequest }) {
        if (!initiateBankStatementRequest.success) {
          return;
        }
        createApplicationTrace(
          pages.mbsBankDetails,
          'MBS Bank Statement Initiated',
        );
        notify.show('OTP sent successfully', status.SUCCESS, duration.SHORT);
      },
    },
  );

  const startBankStatementRequest = () => {
    runInitiateBankStatementRequest({
      variables: {
        applicationId,
        accountBankId: bankId,
        isRetried: false,
      },
    });
  };

  const redirectToOTPPage = () => {
    const path = customForm ? 'application-custom' : 'application';
    history.push(`/${path}/${applicationNumber}/${NEW_LOAN_ROUTES.mbsOtp}`);
    createApplicationTrace(pages.mbsBankDetails, 'Navigated to MBS OTP Screen');
  };

  const redirectToExternal = () => {
    const path = customForm ? 'application-custom' : 'application';
    history.push(
      `/${path}/${applicationNumber}/${NEW_LOAN_ROUTES.mbsInstruction}`,
    );
    createApplicationTrace(
      pages.mbsBankDetails,
      'Navigated to MBS Instruction Screen',
    );
  };

  const resendMBSOTP = () => {
    runInitiateBankStatementRequest({
      variables: {
        applicationId,
        accountBankId: bankId,
        isRetried: true,
      },
    });
  };

  const getPath = path => `/application-custom/${applicationNumber}/${path}`;

  const handleTicketSubmit = useCallback(
    async ({ ticketNum, password, applicationId }) => {
      if (!ticketNum || !password) {
        showErrorAlert('Please enter Ticket Number and Password.');
        return;
      }

      await runCompleteBankStatementRequest({
        variables: { applicationId, ticketNum, password, skipStep: false },
      });
      if (customForm) {
        if (requiresBankStatementUpload) {
          const path = getPath('upload-bank-statement');
          handleNext({
            variables: {
              applicationId,
              data: { path },
            },
          });
          history.push(path);
        } else {
          handleNext({
            variables: {
              applicationId,
              data: { step: formIndex + 1, path: '' },
            },
          });
        }
      }
    },
    [],
  );

  const handleSkip = async () => {
    await runCompleteBankStatementRequest({
      variables: { applicationId, skipStep: true },
    });
    if (customForm) {
      const path = getPath('upload-bank-statement');
      handleNext({
        variables: {
          applicationId,
          data: { path },
        },
      });
      history.push(path);
    }
  };

  const handleExternalSubmit = () => {
    setIsFromExternal(true);
    redirectToOTPPage();
  };

  return {
    customMbsDates,
    clientInfo: { clientName },
    goToPreviousPage,
    loading,
    handleTicketSubmit,
    resendMBSOTP,
    bankName,
    applicationLoading,
    handleExternalSubmit,
    handleSkip,
    startBankStatementRequest,
    redirectToExternal,
    redirectToOTPPage,
    application,
    isFromExternal,
  };
};

export default useMbsForm;
