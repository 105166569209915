import { useMutation } from '@apollo/client';
import { Form, Formik, useFormikContext } from 'formik';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { Alert, DataCard } from '../../../components';
import {
  Checkbox,
  Input,
  NumberInput,
  Select,
  SubmitButton,
} from '../../../components/FormikElements';
import config from '../../../config/config';
import { getClientId, useSignupContext, useUserViewer } from '../../../hooks';
import Constants from '../../../lib/constants';
import { logEvent } from '../../../lib/GAHelper';
import {
  dobMaxSelectableDate,
  generateCardReference,
  titleCase,
  trimGraphQLErrors,
} from '../../../lib/utils';
import { CREATE_CUSTOMER } from '../mutations';
import { createAccountValidationSchema } from '../../../lib/validationSchemas';
import RequirementsPopup from './RequirementsPopup';
import * as S from './styled';
import DatePicker from 'react-datepicker';
import naijaXbyState from 'naija-xbystate';
import { BvnValidator } from '../../../components/BvnValidator';

const getLocalGovernmentArea = state => {
  if (state !== '') {
    return naijaXbyState
      .lgas(state)
      .map((lga, index) => <option key={index}>{lga}</option>);
  }
};

const CreateNewAccount = ({ history }) => {
  const {
    signupState,
    setSignupState,
    createContextState,
    triggerEmailConfirmation,
    populateStates,
    clientInfo: {
      slug: clientSlug,
      customerMaritalStatus,
      customerGender,
      termsAndConditionsUrl,
      privacyPolicyUrl,
      name: clientName,
    },
  } = useSignupContext();
  const { useGeoFence, checkedRadius, withinRadius } = signupState;
  const [createAccountError, setCreateAccountError] = useState(null);
  const [verificationFormState, setVerificationFormState] = useState({
    bvn: '',
    otpChannel: '',
    bvnOtp: '',
  });
  const [userHasVerifiedBvn, setUserHasVerifiedBvn] = useState(false);
  const [verifiedUserData, setVerifiedUserData] = useState(null);
  const [openPopup, setOpenPopup] = useState(true);
  const [fieldType, setFieldType] = useState({
    password: 'password',
    confirmPassword: 'password',
  });
  const currentUrl = window.location.href;
  const clientId = getClientId();
  const { viewerData, loading: userLoading } = useUserViewer();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [createAccountError]);

  useEffect(() => {
    const user = viewerData?.viewer?.me || {};
    if (user?.id && !user?.isPhoneConfirmed) {
      history.push('/sign-up/verify-phone');
      return;
    }

    if (user?.id && !user?.isEmailConfirmed) {
      history.push('/sign-up/verify-email');
      return;
    }

    if (user?.id) {
      history.push('/', { login: true });
    }
  }, [viewerData, userLoading]);

  const [createCustomerMutation, { loading: createCustomerLoading }] =
    useMutation(CREATE_CUSTOMER, {
      onError: error => {
        const errorMessage = trimGraphQLErrors(error);
        setCreateAccountError(
          errorMessage ||
            'Error creating your account. Please, try again later',
        );
      },
      onCompleted: data => {
        handleReturedData(data);
      },
    });

  const maritalStatus = useMemo(() => {
    return customerMaritalStatus.map((status, index) => {
      return (
        <option key={index} value={status.name}>
          {status.name}
        </option>
      );
    });
  }, [customerMaritalStatus]);

  const gender = useMemo(() => {
    return customerGender.map((gender, index) => {
      return (
        <option key={index} value={gender.name}>
          {gender.name}
        </option>
      );
    });
  }, [customerGender]);

  const createCustomer = useCallback(
    async values => {
      logEvent('Signup', 'Create Customer.');

      createCustomerMutation({
        variables: {
          ...values,
          phone: values.phoneNumber.substring(1),
          residentialAddress: `${
            values.residentialAddressNumber
              ? values.residentialAddressNumber +
                ', ' +
                values.residentialAddress
              : values.residentialAddress
          }`,
          clientId,
        },
      });

      setSignupState(prevState => ({
        ...prevState,
        phoneNumber: values.phoneNumber,
        email: values.email,
        dateOfBirth: values.dateOfBirth,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        residentialAddressNumber: values.residentialAddressNumber,
        residentialAddress: values.residentialAddress,
        residentialState: values.residentialState,
        localGovernment: values.localGovernment,
      }));
    },
    [signupState],
  );

  const handleReturedData = useCallback(
    ({ createCustomer }) => {
      const { token, account, user } = createCustomer;

      const payStackMetadata = {
        accountId: account.id,
        cardReference: generateCardReference(),
        transactionType: Constants.transactType.transactionType,
      };

      setSignupState(prevState => ({
        ...prevState,
        payStackMeta: payStackMetadata,
        user,
      }));

      const authCreds = {
        apiKey: token,
        keyType: Constants.authTypes.TEMPORARY,
      };

      localStorage.setItem('Auth', JSON.stringify(authCreds));
      triggerEmailConfirmation();
      createContextState('verify-phone', {
        payStackMeta: payStackMetadata,
      });
      history.push('/sign-up/verify-phone');
    },
    [signupState],
  );

  const toggleInputType = field => {
    setFieldType(prevState => ({
      ...prevState,
      [field]: prevState[field] === 'password' ? 'text' : 'password',
    }));
  };

  if (
    config.web.node_env === 'production' &&
    useGeoFence &&
    currentUrl !== `https://${clientSlug}.indicina.net/` &&
    checkedRadius &&
    !withinRadius
  ) {
    return (
      <DataCard>
        <h3 className="center-text">Error</h3>
        <Alert classes="error">
          Sorry we can not offer you a loan because you're not within our
          service radius.
        </Alert>
      </DataCard>
    );
  }

  return (
    <>
      {openPopup ? (
        <RequirementsPopup setOpenPopup={setOpenPopup} />
      ) : (
        <>
          {!userHasVerifiedBvn && (
            <BvnValidator
              setUserHasVerifiedBvn={setUserHasVerifiedBvn}
              setVerifiedUserData={setVerifiedUserData}
              setVerificationFormState={setVerificationFormState}
              verificationFormState={verificationFormState}
            />
          )}
          {userHasVerifiedBvn && verifiedUserData && (
            <S.Wrapper>
              <DataCard loading={createCustomerLoading}>
                <S.Title>Create Account</S.Title>

                {createAccountError && (
                  <Alert classes="error">{createAccountError}</Alert>
                )}
                {!openPopup && (
                  <Formik
                    initialValues={{
                      bvn: '',
                      dateOfBirth: '',
                      maritalStatus: '',
                      phoneNumber: '',
                      email: '',
                      gender: '',
                      password: '',
                      confirmPassword: '',
                      residentialAddressNumber: '',
                      residentialAddress: '',
                      residentialState: '',
                      localGovernment: '',
                      termsandagreement: false,
                      privacyPolicy: privacyPolicyUrl ? false : true,
                    }}
                    validationSchema={createAccountValidationSchema}
                    onSubmit={values => {
                      createCustomer(values);
                    }}
                  >
                    {({ values, setFieldValue, handleBlur }) => (
                      <>
                        <Form>
                          <>
                            <S.PDisclosure className="active">
                              <Fragment>
                                <NumberInput
                                  name="bvn"
                                  disabled={!!verificationFormState.bvn}
                                  placeholder="BVN"
                                  maxLength="11"
                                  decimalSeparator={false}
                                />
                                <S.TwoColumn>
                                  <Input
                                    disabled={!!verifiedUserData.firstName}
                                    name="firstName"
                                    placeholder="First Name"
                                  />
                                  <Input
                                    disabled={!!verifiedUserData.lastName}
                                    name="lastName"
                                    placeholder="Last Name"
                                  />
                                </S.TwoColumn>

                                <DatePicker
                                  readOnly={!!verifiedUserData.dateOfBirth}
                                  placeholderText="Date of Birth"
                                  name="dateOfBirth"
                                  selected={
                                    values.dateOfBirth
                                      ? new Date(values.dateOfBirth)
                                      : null
                                  }
                                  onChange={date => {
                                    setFieldValue(
                                      'dateOfBirth',
                                      date ? date.toISOString() : '',
                                    );
                                  }}
                                  showYearDropdown
                                  dateFormat="dd/MMMM/yyyy"
                                  showMonthDropdown
                                  dropdownMode="select"
                                  className="datepicker"
                                  autoComplete="off"
                                  maxDate={dobMaxSelectableDate}
                                  onBlur={handleBlur}
                                />

                                <S.TwoColumn>
                                  <Select name="maritalStatus">
                                    <option value="" disabled>
                                      --Marital Status--
                                    </option>
                                    {maritalStatus}
                                  </Select>
                                  <Select
                                    name="gender"
                                    disabled={!!verifiedUserData.gender}
                                    autoComplete="gender"
                                  >
                                    <option disabled value="">
                                      -- Gender --
                                    </option>
                                    {gender}
                                  </Select>
                                </S.TwoColumn>

                                <NumberInput
                                  phoneField
                                  name="phoneNumber"
                                  placeholder="07030000000"
                                  maxLength="11"
                                />
                                <Input
                                  name="email"
                                  type="email"
                                  placeholder="Email Address"
                                />

                                <S.AddressTwoColumn>
                                  <Input
                                    name="residentialAddressNumber"
                                    placeholder="House Number"
                                    type="number"
                                  />

                                  <Input
                                    name="residentialAddress"
                                    placeholder="Residential Address"
                                  />
                                </S.AddressTwoColumn>

                                <S.TwoColumn>
                                  <Select name="residentialState">
                                    <option value="" disabled>
                                      --Select State--
                                    </option>
                                    {populateStates}
                                  </Select>
                                  <Select name="localGovernment">
                                    <option value="" disabled>
                                      --Select Local Government--
                                    </option>
                                    {values.residentialState &&
                                      getLocalGovernmentArea(
                                        values.residentialState,
                                      )}
                                  </Select>
                                </S.TwoColumn>

                                <S.TwoColumn>
                                  <S.PasswordField>
                                    <Input
                                      name="password"
                                      type={fieldType.password}
                                      placeholder="Password"
                                    />
                                    <S.ToggleButton
                                      type="button"
                                      onClick={() =>
                                        toggleInputType('password')
                                      }
                                    >
                                      {fieldType.password === 'password'
                                        ? 'show'
                                        : 'hide'}
                                    </S.ToggleButton>
                                  </S.PasswordField>
                                  <S.PasswordField>
                                    <Input
                                      name="confirmPassword"
                                      type={fieldType.confirmPassword}
                                      placeholder="Confirm Password"
                                    />
                                    <S.ToggleButton
                                      type="button"
                                      onClick={() =>
                                        toggleInputType('confirmPassword')
                                      }
                                    >
                                      {fieldType.confirmPassword === 'password'
                                        ? 'show'
                                        : 'hide'}
                                    </S.ToggleButton>
                                  </S.PasswordField>
                                </S.TwoColumn>

                                <Checkbox name="termsandagreement">
                                  I agree to the{' '}
                                  {termsAndConditionsUrl ? (
                                    <a
                                      href={termsAndConditionsUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {' Terms and Conditions '}
                                    </a>
                                  ) : (
                                    ' Terms and Conditions '
                                  )}{' '}
                                  of this loan
                                </Checkbox>
                                {privacyPolicyUrl && (
                                  <Checkbox name="privacyPolicy">
                                    We are NDPR Compliant. By using this form,
                                    you agree to the storage and usage of your
                                    data by {titleCase(clientName)} in
                                    accordance with our{' '}
                                    <a
                                      href={privacyPolicyUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Privacy Policy.
                                    </a>
                                  </Checkbox>
                                )}
                              </Fragment>
                            </S.PDisclosure>

                            <S.Aside>
                              NOTE: Dial *565*0# on your Bank registered mobile
                              number to get your BVN
                            </S.Aside>

                            <SubmitButton
                              disabled={
                                !values.bvn ||
                                !values.lastName ||
                                !values.dateOfBirth ||
                                !values.termsandagreement
                              }
                              type="submit"
                              value="Next"
                            />

                            <S.Aside>
                              Already have an account?{' '}
                              <Link to="/sign-in"> Sign In </Link>
                            </S.Aside>
                          </>
                        </Form>
                        <BvnVerification
                          bvnDetails={verifiedUserData}
                          verificationFormState={verificationFormState}
                        />
                      </>
                    )}
                  </Formik>
                )}
              </DataCard>
            </S.Wrapper>
          )}
        </>
      )}
    </>
  );
};

export const BvnVerification = ({ bvnDetails, verificationFormState }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (bvnDetails || verificationFormState) {
      setFieldValue('firstName', bvnDetails?.firstName);
      setFieldValue('lastName', bvnDetails?.lastName);
      setFieldValue('gender', bvnDetails?.gender?.toString()?.toUpperCase());
      setFieldValue('dateOfBirth', bvnDetails?.dateOfBirth);
      setFieldValue('bvn', verificationFormState?.bvn);
    }
  }, [bvnDetails, verificationFormState]);

  return null;
};

export default CreateNewAccount;
