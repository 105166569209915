import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useApolloClient } from '@apollo/client';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import JwtDecode from 'jwt-decode';

import './_App.scss';
import {
  Layout,
  AuthenticatedLayout,
  PreLoginRoute,
  Page,
  ProtectedRoute,
  NotFound,
} from '../../components';

import { initializeGA, logPageView } from '../../lib/GAHelper';

import config from '../../config/config';
import { ConfirmEmail } from '../index';
import { authGetter } from '../../lib/utils';
import Merchant from '../providers/Client/ClientInfo';
import { UserViewerProvider } from '../../context/userViewerContext';

const App = () => {
  const authentication = authGetter();

  const client = useApolloClient();
  const history = createBrowserHistory();

  useEffect(() => {
    if (config.web.app_env === 'development') {
      // eslint-disable-next-line no-console
      console.log(
        " _               _   _          _              \n(_)             | | (_)        (_)\n _   _ __     __| |  _    ___   _   _ __     __ -\n| | | '_ \\   / _  | | |  / __| | | | '_ \\   / _' |\n| | | | | | ( (_| | | | ( (__  | | | | | | ( (_| |\n|_| |_| |_|  \\__,_| |_|  \\___| |_| |_| |_|  \\__,_|\n",
      );
    }

    initializeGA();
    logPageView();

    if (
      config.web.node_env === 'staging' ||
      config.web.node_env === 'production'
    ) {
      if (window.location.protocol !== 'https:') {
        window.location.href = `https:${window.location.href.substring(
          window.location.protocol.length,
        )}`;
      }
    }
    if (config.web.node_env === 'production') {
      hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv);
    }

    if (authentication.hasApiKey) {
      clearUsertokenAndLogout();
    }
  });

  const clearUsertokenAndLogout = async () => {
    const expiryDate = JwtDecode(authentication.apiKey).exp;
    const currentTime = new Date().getTime() / 1000;

    if (currentTime > expiryDate) {
      window.location.href = '/';
      localStorage.clear();
      await client.resetStore();
    }
  };

  return (
    <UserViewerProvider>
      <Merchant>
        <Router history={history}>
          <Switch>
            <ProtectedRoute
              path="/dashboard"
              exact
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/loans"
              exact
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/loans/details"
              exact
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/application"
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/application-custom"
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/loans/repay-loan"
              exact
              component={AuthenticatedLayout}
            />
            <ProtectedRoute
              path="/loans/repay-offline"
              exact
              component={AuthenticatedLayout}
            />
            <ProtectedRoute path="/settings" component={AuthenticatedLayout} />
            <PreLoginRoute path="/" exact component={Layout} />
            <PreLoginRoute path="/sign-in" component={Layout} />
            <PreLoginRoute path="/sign-up" component={Layout} />

            <PreLoginRoute path="/forgot-password" component={Layout} />

            <PreLoginRoute
              path="/reset-password-confirmation"
              component={Layout}
            />

            <PreLoginRoute path="/password-reset-complete" component={Layout} />

            <PreLoginRoute
              path="/reset-password/:resetToken"
              component={Layout}
            />

            <Route
              path="/confirm-email/:emailToken"
              render={props => (
                <Page
                  {...props}
                  component={ConfirmEmail}
                  title="Confirm Email"
                />
              )}
            />

            <Route
              path=""
              exact
              render={props => (
                <Page {...props} component={NotFound} title="Page not found" />
              )}
            />
          </Switch>
        </Router>
      </Merchant>
    </UserViewerProvider>
  );
};

export default App;
