import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { isBefore, isAfter } from 'date-fns';
import { UPDATE_USER_DATA_MUTATION, VIEWER_QUERY } from './queries';
import { useFormInput, useFlashMessage } from '../../Form';
import { removeCommas } from '../../../lib/utils';

const isDefaultWorkStartdate = (optionValue, initialValue, timeProps) => {
  if (!initialValue || !timeProps) return false;
  const { oneMonthAgo, oneYearAgo, fourYearsAgo, sevenYearsAgo, tenYearsAgo } =
    timeProps;

  if (optionValue === oneMonthAgo && isAfter(initialValue, oneYearAgo)) {
    return true;
  }
  if (
    optionValue === oneYearAgo &&
    isAfter(initialValue, fourYearsAgo) &&
    isBefore(initialValue, oneYearAgo)
  ) {
    return true;
  }
  if (
    optionValue === fourYearsAgo &&
    isAfter(initialValue, sevenYearsAgo) &&
    isBefore(initialValue, fourYearsAgo)
  ) {
    return true;
  }
  if (
    optionValue === sevenYearsAgo &&
    isAfter(initialValue, tenYearsAgo) &&
    isBefore(initialValue, sevenYearsAgo)
  ) {
    return true;
  }
  if (optionValue === tenYearsAgo && isBefore(initialValue, tenYearsAgo)) {
    return true;
  }
};

const UpdateUserDetailsProvider = ({ children, user, timeProps }) => {
  let metaArray = [];
  if (user.userMetadata && user.userMetadata.length > 0) {
    metaArray = user.userMetadata;
  }

  const getMetaValue = (name, defaultValue = null) => {
    const meta = metaArray.find(meta => meta.name === name);
    return meta ? meta.value : defaultValue;
  };

  let inputs = {};

  const textMetaFields = [
    'address',
    'state',
    'employerName',
    'workAddress',
    'workEmail',
    'jobRole',
    'grossIncome',
    'netIncome',
    'refereeName',
    'refereePhone',
    'refereeEmail',
    'refereeWorkPlace',
  ];
  textMetaFields.forEach(
    field => (inputs[field] = useFormInput(getMetaValue(field))),
  );

  inputs.workStartDate = useFormInput(getMetaValue('workStartDate'), {
    type: 'radio',
    isDefaultChecked: (optionValue, initialValue) =>
      isDefaultWorkStartdate(optionValue, initialValue, timeProps),
  });

  inputs.employmentStatus = useFormInput(getMetaValue('employmentStatus'), {
    type: 'radio',
    isDefaultChecked: (optionValue, initialValue) =>
      optionValue === initialValue,
  });

  const [errorMessage, flashError, setErrorMessage] = useFlashMessage();
  const [successMessage, flashSuccess] = useFlashMessage();

  const updateDetails = (e, runMutation) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorMessage('');

    const input = {};

    Object.entries(inputs).forEach(([key, { initialValue, value }]) => {
      if (initialValue !== value) input[key] = value;
      if (key === 'netIncome') input[key] = removeCommas(value);
    });

    if (Object.keys(input).length > 0) {
      runMutation({ variables: { input } });
    } else {
      flashError('You have not made any changes');
    }
  };

  return (
    <Mutation
      mutation={UPDATE_USER_DATA_MUTATION}
      onCompleted={({ updateUserData: { success } }) =>
        success && flashSuccess('Your details have been updated')
      }
      onError={error => {
        if (error.graphQLErrors.length === 0)
          flashError('Something Went Wrong: Try again later');

        error.graphQLErrors.forEach(error => {
          switch (error.message) {
            default:
              flashError('Something Went Wrong: Try Again Later');
          }
        });
      }}
      update={(
        cache,
        { data: { updateUserData: { success, records } = {} } },
      ) => {
        if (success && records) {
          const { viewer } = cache.readQuery({ query: VIEWER_QUERY });

          records.forEach(meta => {
            if (!viewer.me.userMetadata.find(({ id }) => meta.id === id)) {
              // if record was missing add result to userMetadata array in cache
              viewer.me.userMetadata.push(meta);
            }
          });
          cache.writeQuery({ query: VIEWER_QUERY, data: { viewer } });
        }
      }}
    >
      {(runMutation, { error, loading }) =>
        children({
          onSubmit: e => updateDetails(e, runMutation),
          loading,
          error,
          errorMessage,
          successMessage,
          form: inputs,
        })
      }
    </Mutation>
  );
};

export default UpdateUserDetailsProvider;
