import { useLoanApplicationContext } from '../../../hooks';
import { useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';

const useRefereeDetailsForm = () => {
  const {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    indexSlug,
    clientInfo: { requiresReferee, requiredSupportingDocuments },
    goToPreviousPage,
    goToNextPage,
    user,
    userLoading,
    setCompletedStep,
    loanApplicationIsLoading,
  } = useLoanApplicationContext();

  const [currentReferee, setCurrentReferee] = useState({});

  const addReferee = values => {
    setLoanApplicationState(prevState => ({
      ...prevState,
      ...values,
    }));
    updateUserDetails({ ...values }, () => {
      setCompletedStep('requireReferee');
      goToNextPage();
    });
  };

  useEffect(() => {
    createApplicationTrace(
      pages.referee,
      'Navigated to Referee Details Screen',
    );
  }, []);

  useEffect(() => {
    if (!userLoading) {
      const { userMetadata } = user || {};
      setCurrentReferee({
        refereeName: userMetadata?.find(meta => meta.name === 'refereeName')
          ?.value,
        refereePhone: userMetadata?.find(meta => meta.name === 'refereePhone')
          ?.value,
        refereeWorkPlace: userMetadata?.find(
          meta => meta.name === 'refereeWorkPlace',
        )?.value,
        refereeEmail: userMetadata?.find(meta => meta.name === 'refereeEmail')
          ?.value,
      });
    }
  }, [user, userLoading]);

  return {
    updateUserDetails,
    setLoanApplicationState,
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    indexSlug,
    clientInfo: { requiresReferee, requiredSupportingDocuments },
    currentReferee,
    addReferee,
    loanApplicationIsLoading,
    goToPreviousPage,
    userLoading,
  };
};

export default useRefereeDetailsForm;
