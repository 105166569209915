import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Label = styled.label`
  display: block;
  position: relative;
  padding: 1rem 1rem 1rem 4rem;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: 600;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme }) => theme.secondaryColor};
    border: 0;
  }
`;

export const Span = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid black;

  :after {
    content: '';
    position: absolute;
    top: 5.5px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`;

export const Error = styled.p`
  color: red;
  font-size: 1.1rem;
  margin: 0;
`;
