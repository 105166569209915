import React, { useState, useEffect, createContext } from 'react';
import { initializeGA, logPageView } from '../../lib/GAHelper';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SINGLE_APPLICATION_QUERY } from '../providers/User/queries';
import { handleGraphQLErrors } from '../../lib/utils';
import { notify } from 'react-notify-toast';
import Constants from '../../lib/constants';
import { useHistory } from 'react-router';
import { useClientInfoContext } from '../../hooks';

export const ApplicationContext = createContext({});

export const ApplicationProvider = ({ children }) => {
  const [newLoanState, setNewLoanState] = useState({});
  const history = useHistory();
  const [categoryId, setCategoryId] = useState();
  const {
    params: { applicationNumber },
  } = useRouteMatch();

  const { status, duration } = Constants;

  const { data, loading, refetch } = useQuery(SINGLE_APPLICATION_QUERY, {
    variables: { applicationNumber },
    onError(error) {
      const errorMessage = handleGraphQLErrors(error);
      notify.show(
        errorMessage ||
          'There was an error loading you application. Try again later',
        status.ERROR,
        duration.LONG,
      );
      history.push('/dashboard');
    },
    onCompleted({ application }) {
      if (application.status.name !== 'PENDING') {
        history.push(`/loans/details?applicationNumber=${applicationNumber}`);
      }

      setCategoryId(application?.loanCategory?.id);
    },
  });

  useEffect(() => {
    setNewLoanState(prevState => ({
      ...prevState,
      applicationNumber,
      closePayStack: false,
    }));
    initializeGA();
    logPageView();
  }, []);

  const { clientInfo, refetch: fetchClientInfo } = useClientInfoContext({
    loanCategoryId: categoryId,
  });

  useEffect(() => {
    fetchClientInfo();
  }, [categoryId]);

  return (
    <ApplicationContext.Provider
      value={[
        newLoanState,
        setNewLoanState,
        { data, loading, refetch },
        clientInfo,
        categoryId,
      ]}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
