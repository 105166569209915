import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useSignupContext, useUserViewer } from '../../hooks';

const SignupRoute = ({ path, component }) => {
  const { viewerData, loading: userLoading } = useUserViewer();
  const { setSignupState } = useSignupContext();

  useEffect(() => {
    const user = viewerData?.viewer?.me;
    setSignupState(prevState => ({
      ...prevState,
      isPhoneConfirmed: user?.isPhoneConfirmed || false,
      isEmailConfirmed: user?.isEmailConfirmed || false,
    }));
  }, [viewerData, userLoading]);
  return <Route exact path={path} component={component} />;
};

export default SignupRoute;
