import styled from 'styled-components';

const getProgress = pathname => {
  const path = pathname.split('/')[2];
  const totalPages = 27;
  const singlePageProgress = (1 / totalPages) * 100;
  switch (path) {
    case 'create-account':
      return `${singlePageProgress * 1}%`;
    case 'loan-product':
      return `${singlePageProgress * 2}%`;
    case 'verify-phone':
      return `${singlePageProgress * 2}%`;
    case 'verify-bvn-phone':
      return `${singlePageProgress * 3}%`;
    case 'verify-email':
      return `${singlePageProgress * 4}%`;
    case 'upload-selfie':
      return `${singlePageProgress * 5}%`;
    case 'upload-id':
      return `${singlePageProgress * 6}%`;
    case 'home-address':
      return `${singlePageProgress * 7}%`;
    case 'connect-facebook':
      return `${singlePageProgress * 8}%`;
    case 'facebook-url':
      return `${singlePageProgress * 9}%`;
    case 'utility-bill':
      return `${singlePageProgress * 10}%`;
    case 'employment':
      return `${singlePageProgress * 11}%`;
    case 'supporting-documents':
      return `${singlePageProgress * 12}%`;
    case 'employment-letter':
      return `${singlePageProgress * 12}%`;
    case 'upload-work-id':
      return `${singlePageProgress * 13}%`;
    case 'verify-work-email':
      return `${singlePageProgress * 14}%`;
    case 'referee':
      return `${singlePageProgress * 15}%`;
    case 'loan':
      return `${singlePageProgress * 16}%`;
    case 'loan-amount':
      return `${singlePageProgress * 17}%`;
    case 'loan-tenor':
      return `${singlePageProgress * 18}%`;
    case 'onepipe':
      return `${singlePageProgress * 18}%`;
    case 'processing-info':
      return `${singlePageProgress * 19}%`;
    case 'okra':
      return `${singlePageProgress * 20}%`;
    case 'bank-details':
      return `${singlePageProgress * 21}%`;
    case 'mbs':
      return `${singlePageProgress * 22}%`;
    case 'mbs-instruction':
      return `${singlePageProgress * 23}%`;
    case 'external-mbs':
      return `${singlePageProgress * 24}%`;
    case 'upload-bankstatement':
      return `${singlePageProgress * 25}%`;
    case 'breakdown':
      return `${singlePageProgress * 26}%`;
    case 'loan-pending':
      return `100%`;
    case 'failure':
      return '100%';
    default:
      return '90%';
  }
};
export const Progressbar = styled.div`
  width: 85%;
  height: 8px;
  background-color: #ffffff33;
  margin: 0 auto;
  margin-top: 8rem;
  border-radius: 8px;
  max-width: 114rem;

  div {
    width: ${({ pathname }) => getProgress(pathname)};
    border-radius: 8px;
    height: 8px;
    background-color: white;
  }
`;

export const Wrapper = styled.div`
  .data-card {
    margin: 5rem auto;
    max-width: 60rem;
    padding: 6.4rem 4rem;
  }
  h3 {
    text-align: center;
  }
  a {
    text-decoration: underline;
  }
  .hide {
    visibility: hidden;
  }
  .block {
    margin-top: 2rem;
  }
  .datepicker {
    border: 0;
    border-radius: 0;
    margin-top: 3rem;
    border-bottom: 1px solid black;
    padding-left: 0;
    padding-right: 0;

    &_error {
      font-size: 1.1rem;
      color: red;
      margin: 0;
    }
  }
  .react-datepicker__input-container {
    width: inherit;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .datePickerButton {
    border: none;
    margin-top: 3rem;
    background-color: ${({ theme }) => theme.colors.color_white};
    width: inherit;
    text-align: left;
    padding-left: 0;
    border-bottom: 1px solid black;
    span {
      color: #d3d3d3;
    }
  }
`;

export const TwoColumn = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
`;

export const AddressTwoColumn = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 3rem;
`;

export const Title = styled.h4`
  font-size: 2.8rem;
  text-align: center;
`;

export const Subtitle = styled.h5`
  text-align: center;
  font-weight: ${({ theme }) => theme.fonts.font_regular};
`;

export const Aside = styled.aside`
  color: rgba(0, 44, 91, 0.5);
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  margin: 2.4rem 0 0;
  text-align: center;
`;

export const PreviousButton = styled.input`
  background-color: ${({ theme }) => theme.colors.color_white};
  color: ${({ theme }) => theme.secondaryColor};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  cursor: pointer !important;
  margin-top: 1.5rem;
`;

export const NextButton = styled.input`
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.colors.color_white};
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  border: 0;
  cursor: pointer !important;
  margin-top: 1.5rem;
`;

export const ImageUpload = styled.label`
  border: 7px solid ${({ theme }) => theme.secondaryColor};
  border-radius: 50%;
  display: block;
  cursor: pointer;
  height: 13.4rem;
  margin: 0 auto;
  overflow: hidden;
  width: 13.4rem;

  input {
    display: none;
    height: 0;
    padding: 0;
    width: 0;
  }

  section {
    text-align: center;
    img {
      height: 12rem;
      max-width: none;
      object-fit: cover;
      width: 12rem;
    }
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  button {
    color: ${({ theme }) => theme.secondaryColor};
    text-decoration: underline;
    background-color: ${({ theme }) => theme.colors.color_white};
    border: 0;
    cursor: pointer;
    outline: none;
  }
`;
