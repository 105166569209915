import { useImageUploader, useLoanApplicationContext } from '../../../hooks';
import { useCallback, useEffect, useState } from 'react';
import { logEvent } from '../../../lib/GAHelper';
import { pages } from '../../../lib/constants';

const useUploadSelfieForm = () => {
  const {
    applicationLoading,
    createApplicationTrace,
    goToNextPage,
    user,
    setCompletedStep,
  } = useLoanApplicationContext();
  const {
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
  } = useImageUploader();
  const [passportDetails, setPassportDetails] = useState({});

  const type = 'PASSPORT';

  const handleSelfieUpload = useCallback(() => {
    logEvent('Signup', 'Upload Selfie');
    const fileName = `selfie_${user?.firstName}_${user?.lastName}_${user?.id}`;
    uploadImage(passportDetails.fileDetails, fileName, type);
  }, [passportDetails]);

  const handlePreview = e => {
    handleImagePreview(e, setPassportDetails);
  };

  useEffect(() => {
    if (data) {
      setCompletedStep('selfie');
      goToNextPage();
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.uploadSelfie,
      'Navigated to Selfie Upload Screen',
    );
  }, []);

  return {
    applicationLoading,
    createApplicationTrace,
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
    handleSelfieUpload,
    handlePreview,
    passportDetails,
  };
};

export default useUploadSelfieForm;
