import { useImageUploader, useLoanApplicationContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { useCallback, useEffect, useState } from 'react';
import { pages } from '../../../lib/constants';

const useWorkIdUploadForm = () => {
  const {
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    stepCategory,
    clientInfo: { requiresWorkIDUpload },
    goToNextPage,
    user,
    goToPreviousPage,
    setCompletedStep,
  } = useLoanApplicationContext();
  const {
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
  } = useImageUploader();
  const [workIdDetails, setWorkIdDetails] = useState({});

  const type = 'WORKID';

  const handleUpload = useCallback(async () => {
    logEvent(stepCategory, 'Upload Work Id');

    const { firstName, lastName, id } = user;
    const fileName = `work_id_${firstName}_${lastName}_${id}`;
    uploadImage(workIdDetails.fileDetails, fileName, type);
  }, [workIdDetails, user]);

  const handlePreview = e => {
    handleImagePreview(e, setWorkIdDetails);
  };

  useEffect(() => {
    if (data) {
      const {
        uploadImageAndSaveToUserMetaData: { status },
      } = data;

      if (status) {
        setCompletedStep('workId');
        goToNextPage();
      }
    }
  }, [data]);

  useEffect(() => {
    createApplicationTrace(
      pages.uploadWorkId,
      'Navigated to Work ID Upload Screen',
    );
  }, []);
  return {
    loanApplicationState,
    applicationLoading,
    createApplicationTrace,
    stepCategory,
    clientInfo: { requiresWorkIDUpload },
    data,
    uploadImage,
    uploadImageError,
    uploadImageLoading,
    handleImagePreview,
    type,
    workIdDetails,
    handleUpload,
    handlePreview,
    goToPreviousPage,
  };
};

export default useWorkIdUploadForm;
