import React, { useState, useEffect, createContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VIEWER_QUERY } from '../containers/SignUp/queries';

export const UserViewerContext = createContext({});

export const UserViewerProvider = ({ children }) => {
  const [getUserViewer, { loading, data, refetch }] = useLazyQuery(
    VIEWER_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [viewerData, setViewerData] = useState({ loaded: false });

  useEffect(() => {
    if (data) {
      setViewerData(prevState => ({ ...prevState, ...data }));
    }
  }, [data]);

  const values = {
    loading,
    viewerData,
    refetch,
    getUserViewer,
    setViewerData,
  };

  useEffect(() => {
    if (!data) {
      getUserViewer();
    }
  }, [data]);

  return (
    <UserViewerContext.Provider value={values}>
      {children}
    </UserViewerContext.Provider>
  );
};
