import { getClientId, useLoanApplicationContext } from '../../../hooks';
import { useMutation } from '@apollo/client';
import {
  CONFIRM_WORK_EMAIL,
  TRIGGER_WORK_EMAIL_CONFIRMATION,
} from '../mutations';
import { handleGraphQLErrors } from '../../../lib/utils';
import { notify } from 'react-notify-toast';
import { useCallback, useEffect, useState } from 'react';
import freeEmailDomains from 'personal-email-domains';
import { pages } from '../../../lib/constants';

const useWorkEmailVerificationForm = () => {
  const {
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    updateUserDetails,
    userLoading,
    goToPreviousPage,
    goToNextPage,
    indexSlug,
    createApplicationTrace,
    clientInfo: { requireWorkEmail },
    user,
    setCompletedStep,
    loanApplicationIsLoading,
  } = useLoanApplicationContext();
  const [workEmailError, setWorkEmailError] = useState(null);
  const [otpResendSuccess, setOtpResendSuccess] = useState(null);
  const { userMetadata } = user || {};
  const workEmailData = userMetadata?.find(meta => meta.name === 'workEmail');
  const { value: currentWorkEmail } = workEmailData || {};
  const [workEmail, setWorkEmail] = useState(currentWorkEmail);
  const [editWorkEmailPopup, setEditWorkEmailPopup] = useState(false);
  const clientId = getClientId();

  const [confirmWorkEmail, { loading: confirmWorkEmailLoading }] = useMutation(
    CONFIRM_WORK_EMAIL,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setWorkEmailError(errorMessage || 'Unable to confirm your work email.');
        hideAlert(setWorkEmailError);
      },
      onCompleted({ confirmWorkEmail }) {
        const { ok } = confirmWorkEmail;
        if (!ok) {
          setWorkEmailError('Unable to confirm your work email.');
          hideAlert(setWorkEmailError);
        } else {
          setCompletedStep('verifyWorkEmail');
          goToNextPage();
        }
      },
    },
  );

  const [
    triggerWorkEmailConfirmation,
    { loading: workEmailConfirmationLoading },
  ] = useMutation(TRIGGER_WORK_EMAIL_CONFIRMATION, {
    onError(error) {
      const errorMessage = handleGraphQLErrors(error);
      setWorkEmailError(
        errorMessage || 'Unable to resend OTP. Please, try again later.',
      );
      hideAlert(setWorkEmailError);
    },
    onCompleted({ triggerWorkEmailConfirmation }) {
      const { ok } = triggerWorkEmailConfirmation;
      if (!ok) setWorkEmailError('Unable to send OTP.');
      else {
        setOtpResendSuccess(`OTP sent to ${workEmail}`);
        hideAlert(setOtpResendSuccess);
      }
    },
  });

  const hideAlert = state => {
    setTimeout(() => {
      state(null);
    }, 5000);
  };

  const handleSubmit = useCallback(
    async ({ workEmailVerificationCode }) => {
      await confirmWorkEmail({
        variables: {
          code: workEmailVerificationCode,
          workEmail,
        },
      });
    },
    [workEmail],
  );

  const checkWorkEmailValidation = values => {
    const errors = {};

    if (requireWorkEmail && !values.workEmail) {
      errors.workEmail = 'Work email is required';
    }
    const splitEmail = values.workEmail ? values.workEmail.split('@') : '';
    const blacklistedDomain = freeEmailDomains.includes(splitEmail[1]);
    if (blacklistedDomain) {
      notify.show(
        'Your work email is not from a supported email provider. Please enter another work email',
        'error',
        6000,
      );
      errors.workEmail = 'Please enter a supported work email';
    }
    return errors;
  };

  const openEditButtonPopup = () => {
    setEditWorkEmailPopup(true);
  };

  const closeEditButtonPopup = () => {
    setEditWorkEmailPopup(false);
  };

  const resendEmailOTP = useCallback(async () => {
    await triggerWorkEmailConfirmation({
      variables: {
        clientId,
        workEmail,
      },
    });
  }, [workEmail]);

  const handleWorkEmailEdit = useCallback(async values => {
    setWorkEmail(values.workEmail);
    await triggerWorkEmailConfirmation({
      variables: {
        clientId,
        workEmail: values.workEmail,
      },
    });
    closeEditButtonPopup();
  }, []);

  useEffect(() => {
    if (currentWorkEmail) {
      setWorkEmail(currentWorkEmail);
    }
    if (currentWorkEmail) {
      triggerWorkEmailConfirmation({
        variables: {
          clientId,
          workEmail: currentWorkEmail,
        },
      });
    }
  }, []);

  useEffect(() => {
    createApplicationTrace(
      pages.workEmailVerification,
      'Work Email Verification Screen',
    );
  }, []);

  return {
    updateUserDetails,
    loanApplicationState,
    setLoanApplicationState,
    applicationLoading,
    goToNextPage,
    goToPreviousPage,
    userLoading,
    indexSlug,
    createApplicationTrace,
    clientInfo: { requireWorkEmail },
    otpResendSuccess,
    workEmailError,
    setOtpResendSuccess,
    workEmail,
    editWorkEmailPopup,
    setEditWorkEmailPopup,
    confirmWorkEmailLoading,
    workEmailConfirmationLoading,
    handleSubmit,
    checkWorkEmailValidation,
    handleWorkEmailEdit,
    openEditButtonPopup,
    resendEmailOTP,
    closeEditButtonPopup,
    loanApplicationIsLoading,
  };
};

export default useWorkEmailVerificationForm;
