import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  FormGroup,
  Input,
  Loader,
  Select,
} from '../../components';
import {
  UpdateUserDetailsProvider,
  UpdateUserEmailProvider,
  ViewerProvider,
} from '../providers';
import './_Settings.scss';
import UpdateBvnData from './UpdateBvnData';
import { SignupProvider } from '../SignUp/SignupContext';

const nigeriaStates = [
  'Abia',
  'Adamawa',
  'Anambra',
  'Akwa Ibom',
  'Bauchi',
  'Bayelsa',
  'Benue',
  'Borno',
  'Cross River',
  'Delta',
  'Ebonyi',
  'Enugu',
  'Edo',
  'Ekiti',
  'FCT - Abuja',
  'Gombe',
  'Imo',
  'Jigawa',
  'Kaduna',
  'Kano',
  'Katsina',
  'Kebbi',
  'Kogi',
  'Kwara',
  'Lagos',
  'Nasarawa',
  'Niger',
  'Ogun',
  'Ondo',
  'Osun',
  'Oyo',
  'Plateau',
  'Rivers',
  'Sokoto',
  'Taraba',
  'Yobe',
  'Zamfara',
];

const populateStates = nigeriaStates.map((state, index) => (
  <option value={state} key={index}>
    {state}
  </option>
));

export const ProfileSettings = () => {
  const [updateBvnVerificationState, setUpdateBvnVerificationState] = useState({
    openPopUp: false,
    isUpdateBvnData: true,
    bvn: null,
  });
  const [profileBvn, setProfileBvn] = useState(null);
  const [userHasBvn, setUserHasBvn] = useState(null);

  useEffect(() => {
    if (userHasBvn === false) {
      const element = document.getElementById('phone');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [userHasBvn]);

  return (
    <>
      <SignupProvider>
        {updateBvnVerificationState?.openPopUp && (
          <UpdateBvnData
            updateBvnVerificationState={updateBvnVerificationState}
            setUpdateBvnVerificationState={setUpdateBvnVerificationState}
          />
        )}
        <ViewerProvider>
          {({ user, clientInfo }) => {
            setUserHasBvn(user?.isBvnVerified);

            if (user?.bvnStatus?.bvn && !user?.isBvnVerified) {
              setProfileBvn(user?.bvnStatus.bvn);
            }

            return (
              <section className={'container'}>
                <Card title={'Edit Full Name'} subtitle={'Your Name'}>
                  <FormGroup classes="two-grid">
                    <Input
                      label="First Name"
                      classes="border-bottom"
                      placeholder="First Name"
                      name="firstName"
                      readOnly
                      value={user.firstName}
                    />
                    <Input
                      label="Last Name"
                      classes="border-bottom"
                      placeholder="Last Name"
                      name="lastName"
                      readOnly
                      value={user.lastName}
                    />
                  </FormGroup>
                </Card>
                <Card
                  title={'Email Address'}
                  subtitle={
                    'We send notifications and other account updates to your confirmed email address.'
                  }
                >
                  <UpdateUserEmailProvider>
                    {({ loading }) => (
                      <Fragment>
                        {loading && <Loader />}
                        <FormGroup classes="two-grid">
                          <Input
                            label="email address"
                            classes="border-bottom email-change_input"
                            placeholder="Email Address"
                            name="email"
                            readOnly
                            value={user.email}
                          />
                        </FormGroup>
                      </Fragment>
                    )}
                  </UpdateUserEmailProvider>
                </Card>
                <Card
                  title={'Phone Number'}
                  subtitle={
                    'We send sms verification messages to your phone number.'
                  }
                >
                  <FormGroup classes="two-grid">
                    <Input
                      id="phone"
                      label="Phone Number"
                      classes="border-bottom"
                      placeholder="Phone Number"
                      name="phone"
                      phoneField
                      readOnly
                      defaultValue={user.phone}
                    />
                  </FormGroup>
                </Card>

                <Card
                  title={'Bank Verification Number (BVN)'}
                  subtitle={
                    'We require your BVN to fetch and verify your information.'
                  }
                >
                  <FormGroup classes="two-grid">
                    <div className="flex">
                      <Input
                        label="BVN"
                        classes="border-bottom"
                        placeholder="Kindly enter your BVN"
                        name="bvn"
                        numberField
                        maxLength="11"
                        onChange={e => setProfileBvn(e?.target?.value)}
                        readOnly={user?.bvnStatus?.bvn}
                        defaultValue={user?.bvnStatus?.bvn}
                      />
                      {!user?.isBvnVerified && (
                        <Button
                          click_event={() =>
                            setUpdateBvnVerificationState(prev => ({
                              ...prev,
                              openPopUp: true,
                              bvn: profileBvn,
                            }))
                          }
                          disabled={!(profileBvn?.length === 11)}
                        >
                          Verify Bvn
                        </Button>
                      )}
                    </div>
                  </FormGroup>
                </Card>

                <UpdateUserDetailsProvider user={user}>
                  {({
                    form,
                    onSubmit,
                    loading,
                    errorMessage,
                    successMessage,
                  }) => (
                    <Fragment>
                      {clientInfo && clientInfo.requiresReferee === 'true' ? (
                        <Card
                          title={'Guarantor Information'}
                          subtitle={
                            'We need valid guarantor information for your loan applications'
                          }
                        >
                          <FormGroup classes="two-grid">
                            <Input
                              label="Guarantor Name"
                              classes="border-bottom"
                              placeholder="Guarantor Name"
                              name="refereeName"
                              readOnly
                              {...form.refereeName.input}
                            />
                            <Input
                              label="Guarantor Number"
                              classes="border-bottom"
                              placeholder="Guarantor Number"
                              name="refereePhone"
                              readOnly
                              {...form.refereePhone.input}
                            />
                          </FormGroup>
                          <FormGroup classes="two-grid">
                            <Input
                              label="Guarantor Email"
                              classes="border-bottom"
                              placeholder="Guarantor Email"
                              name="refereeEmail"
                              readOnly
                              {...form.refereeEmail.input}
                            />
                            <Input
                              label="Guarantor Address"
                              classes="border-bottom"
                              placeholder="Guarantor Address"
                              name="refereeWorkPlace"
                              readOnly
                              {...form.refereeWorkPlace.input}
                            />
                          </FormGroup>
                        </Card>
                      ) : (
                        ''
                      )}
                      <Card
                        title={'Home Address'}
                        subtitle={'Where do you live?'}
                      >
                        <FormGroup classes="two-grid">
                          <Input
                            label="Home Address"
                            classes="border-bottom"
                            name="address"
                            {...form.address.input}
                          />
                          <Select
                            label="Residential State"
                            name="residentialState"
                            classes="border-bottom"
                            autoComplete="state"
                            {...form.state.input}
                          >
                            <option value="">--Select State--</option>
                            {populateStates}
                          </Select>
                        </FormGroup>
                      </Card>

                      <Card>
                        {errorMessage && (
                          <Alert classes="error">{errorMessage}</Alert>
                        )}
                        {successMessage && (
                          <Alert classes="success">{successMessage}</Alert>
                        )}
                        {loading ? (
                          <Loader />
                        ) : (
                          <Button click_event={onSubmit}>Update Profile</Button>
                        )}
                      </Card>
                    </Fragment>
                  )}
                </UpdateUserDetailsProvider>
              </section>
            );
          }}
        </ViewerProvider>
      </SignupProvider>
    </>
  );
};

export default ProfileSettings;
