import React from 'react';

import { OriginateFormGenerator } from '@indicina1/originate-form-builder';
import { AddCard, BankAccount } from '../../LoanApplication';
import { Button } from '../../../components';
import theme from '../../../stylesheets/theme';

const FormBuilder = ({
  applicationForm,
  handleSubmit,
  formIndex,
  handleNext,
  defaultFormValues,
}) => {
  return (
    <OriginateFormGenerator
      key={`${defaultFormValues}_${formIndex}`}
      tabs={applicationForm}
      onSubmit={handleSubmit}
      step={formIndex}
      cardComponent={
        <AddCard
          customForm={true}
          handleNext={handleNext}
          formIndex={formIndex}
        />
      }
      bankComponent={
        <BankAccount
          customForm={true}
          handleNext={handleNext}
          formIndex={formIndex}
        />
      }
      theme={theme}
      defaultFormValues={defaultFormValues}
    >
      <Button classes={'custom-btn'} type={'submit'}>
        Next
      </Button>
    </OriginateFormGenerator>
  );
};

export default FormBuilder;
