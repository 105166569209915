import React, { useState } from 'react';
import './_LoanDetailsCard.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RepaymentBreakdown, Button, Tag, Popup } from '../index';
import { removeUnderscore, splitCommaToArray } from '../../lib/utils';
import { CdnFileProvider } from '../../containers/providers';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useClientInfoContext } from '../../hooks';
import { NairaAmount } from '../Icon';

const StyledLDCard = styled.section`
  h6 {
    color: ${props => props.theme.secondaryColor};
  }

  .repayment-breakdown {
    .button.secondary {
      border-color: ${props => props.theme.primaryColor};
    }
  }
`;

const LoanDetailsCard = ({
  amountPaid,
  fullAmount,
  status,
  loanDate,
  applicationDate,
  amountDisbursed,
  interestRate,
  numberOfInstallments,
  monthlyInstallment,
  portfolioNumber,
  applicationNumber,
  amountRequested,
  repaymentDate,
  lateRepaymentFee,
  rejectReason,
  history,
  application,
}) => {
  const {
    clientInfo: { termsAndConditionsUrl, bankDetails, noCardImplementation },
  } = useClientInfoContext();
  const [showPopup, setShowPopup] = useState(false);

  const percentComplete = 100 - ((fullAmount - amountPaid) / fullAmount) * 100;

  const handleShowPopup = () => {
    setShowPopup(!showPopup);
  };

  const offerLetter =
    application &&
    application.status &&
    application.reviewFeedbacks &&
    application.reviewFeedbacks.find(
      feedback => feedback.reviewType === 'OFFER_LETTER_REQUEST',
    );

  const offerLetterDocument = offerLetter
    ? offerLetter.documentRequests[0].document
    : {};

  return (
    <StyledLDCard className="loan-details-card">
      <section className="loan-details-card__left">
        <h6>{portfolioNumber || applicationNumber}</h6>
        {portfolioNumber ? (
          <h3>
            <NairaAmount amount={amountPaid} />
            <small>of</small>
            <NairaAmount amount={fullAmount} />
          </h3>
        ) : (
          <h3>
            <NairaAmount amount={amountRequested} />
          </h3>
        )}
        <h5>
          Status:
          <Tag
            status={
              removeUnderscore(status).toLowerCase() === 'closed'
                ? 'paid'
                : status.toLowerCase() === 'disbursed'
                ? 'active'
                : status.toLowerCase() === 'pending_disbursement'
                ? 'approved'
                : removeUnderscore(status).toLowerCase()
            }
          />
          {removeUnderscore(status).toLowerCase() === 'denied' ? (
            <Button classes="button-link" click_event={handleShowPopup}>
              <small>View Reasons</small>
            </Button>
          ) : (
            ''
          )}
          <span></span>
        </h5>

        {portfolioNumber ? (
          <h5>{Math.abs(percentComplete).toFixed(2)}% Complete</h5>
        ) : (
          ''
        )}

        {portfolioNumber && (status === 'DISBURSED' || status === 'OVERDUE') ? (
          <div className="quick-repay">
            {noCardImplementation ? (
              ''
            ) : (
              <Button
                classes="small"
                click_event={() => history.push('/loans/repay-loan')}
              >
                Make Repayment
              </Button>
            )}
          </div>
        ) : (
          ''
        )}

        {showPopup && status.toLowerCase() === 'denied' ? (
          <Popup
            title="Why you didn’t get your loan"
            classes="loan-requirements"
          >
            <ul>
              {rejectReason &&
                splitCommaToArray(rejectReason).map((reason, index) => (
                  <li key={index}>{reason}</li>
                ))}
            </ul>

            <div className="button-list center-text">
              <Button classes="close-button" click_event={handleShowPopup}>
                Close
              </Button>
            </div>
          </Popup>
        ) : showPopup ? (
          <Popup title="Bank Details" classes="loan-requirements">
            <p>You can make payment to the account below:</p>
            {bankDetails ? (
              <ul>
                <li>BANK NAME : {bankDetails.bank.name}</li>
                <li>ACCOUNT NUMBER :{bankDetails.accountNumber}</li>
                <li>ACCOUNT NAME : {bankDetails.accountName}</li>
              </ul>
            ) : (
              ''
            )}

            {portfolioNumber ? (
              <p>
                Use <span className="bold-text">{portfolioNumber}</span> as the
                narrative.
              </p>
            ) : (
              ''
            )}
            <div>
              <p className="bold-text">
                Note: After making the payment, please click continue repayment
                to upload your receipt for payment confirmation.
              </p>
            </div>

            <div className="button-list center-text">
              <Button
                classes="next mb-1"
                click_event={() => history.push('/loans/repay-offline')}
              >
                Continue Repayment
              </Button>
              <Button classes="secondary" click_event={handleShowPopup}>
                Cancel Repayment
              </Button>
            </div>
          </Popup>
        ) : (
          ''
        )}
      </section>

      <section className="loan-details-card__right">
        <RepaymentBreakdown>
          {loanDate ? (
            <li>
              You got this loan on
              <span className="value">{moment(loanDate).format('ll')}</span>
            </li>
          ) : (
            <li>
              Application Date
              <span className="value">
                {moment(applicationDate).format('ll')}
              </span>
            </li>
          )}
          {amountDisbursed ? (
            <li>
              Amount Disbursed
              <span className="value">
                <NairaAmount amount={amountDisbursed} />
              </span>
            </li>
          ) : (
            <li>
              Loan Amount
              <span className="value">
                <NairaAmount amount={amountRequested} />
              </span>
            </li>
          )}
          {interestRate ? (
            <li>
              Interest Rate
              <span className="value">{interestRate}%</span>
            </li>
          ) : (
            ''
          )}
          {numberOfInstallments ? (
            <li>
              Number of Installments
              <span className="value">{numberOfInstallments}</span>
            </li>
          ) : (
            ''
          )}
          {monthlyInstallment ? (
            <li>
              Monthly Installments
              <span className="value">
                <NairaAmount amount={monthlyInstallment} />
              </span>
            </li>
          ) : (
            <li>
              Repayment Amount
              <span className="value">
                <NairaAmount amount={fullAmount} />
              </span>
            </li>
          )}
          <li>
            Repayment Date
            <span className="value">{moment(repaymentDate).format('ll')}</span>
          </li>
          {lateRepaymentFee ? (
            <li>
              Late Fees
              <span className="value">
                <NairaAmount amount={lateRepaymentFee} />
              </span>
            </li>
          ) : (
            ''
          )}
          <div className="quick-repay">
            {termsAndConditionsUrl && (
              <a
                className="button secondary small"
                href={termsAndConditionsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Contract & Terms
              </a>
            )}

            {offerLetter ? (
              <CdnFileProvider file={offerLetterDocument.file}>
                {({ openFile }) => (
                  <Button className="button small" onClick={openFile}>
                    View Offer Letter
                  </Button>
                )}
              </CdnFileProvider>
            ) : (
              ''
            )}
          </div>
        </RepaymentBreakdown>
      </section>
    </StyledLDCard>
  );
};

LoanDetailsCard.defaultProps = {
  amountPaid: 0,
  amountRequested: 0,
  fullAmount: 0,
  status: '',
  loanDate: '',
  applicationDate: '',
  amountDisbursed: 0,
  interestRate: '',
  numberOfInstallments: 0,
  monthlyInstallment: 0,
  portfolioNumber: '',
  rejectReason: '',
  repaymentBreakdown: [],
};

LoanDetailsCard.propTypes = {
  amountPaid: PropTypes.number,
  amountRequested: PropTypes.number,
  fullAmount: PropTypes.number,
  status: PropTypes.string,
  loanDate: PropTypes.string,
  applicationDate: PropTypes.string,
  amountDisbursed: PropTypes.number,
  interestRate: PropTypes.string,
  numberOfInstallments: PropTypes.number,
  monthlyInstallment: PropTypes.number,
  portfolioNumber: PropTypes.string,
  rejectReason: PropTypes.string,

  repaymentAmount: PropTypes.number,
  repaymentDate: PropTypes.any,
  overlay: PropTypes.bool,
  repaymentBreakdown: PropTypes.array,
};

export default withRouter(LoanDetailsCard);
