import React from 'react';
import { MerchantProvider } from '../../../context/merchantContext';
import { ThemeProvider } from 'styled-components';
import theme from '../../../stylesheets/theme';
import { useClientInfoContext } from '../../../hooks';
import { Loader, MaintenancePage, MerchantNotFound } from '../../../components';

const Merchant = ({ children }) => {
  const {
    error,
    clientInfo: { clientTheme },
    loading,
  } = useClientInfoContext();

  if (loading) return <Loader />;

  if (error) {
    if (error.message === 'Cannot find merchant account') {
      return <MerchantNotFound />;
    } else {
      return <MaintenancePage />;
    }
  }

  return (
    <MerchantProvider value={{ ...theme, ...clientTheme }}>
      <ThemeProvider theme={{ ...theme, ...clientTheme }}>
        {children}
      </ThemeProvider>
    </MerchantProvider>
  );
};

export default Merchant;
