import React, { useCallback, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { notify } from 'react-notify-toast';
import { DataCard, Alert, Popup, Button } from '../../../components';
import {
  NumberInput,
  Input,
  SubmitButton,
} from '../../../components/FormikElements';
import { useSignupContext } from '../../../hooks';
import { handleGraphQLErrors } from '../../../lib/utils';
import Constants from '../../../lib/constants';
import { logEvent } from '../../../lib/GAHelper';
import {
  EmailOTPValidationSchema,
  EditEmailValidationSchema,
} from '../../../lib/validationSchemas';
import { CONFIRM_PERSONAL_EMAIL, UPDATE_USER_EMAIL } from '../mutations';
import { NEW_LOAN_ROUTES } from '../../LoanApplication/routes';

const hideAlert = state => {
  setTimeout(() => {
    state(null);
  }, 3000);
};

const PhoneNumberVerification = ({ history }) => {
  const {
    signupState,
    setSignupState,
    signupError,
    signupLoading,
    updateAuthType,
    updateContextState,
    triggerEmailConfirmation,
  } = useSignupContext();
  const [emailError, setEmailError] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [alertSuccess, setAlertSuccess] = useState(null);
  const { email, user } = signupState;
  const [editEmailPopup, setEditEmailPopup] = useState(false);
  const { status, duration } = Constants;

  const [confirmPersonalEmail, { loading: confirmPersonalEmailLoading }] =
    useMutation(CONFIRM_PERSONAL_EMAIL, {
      onError() {
        notify.show(
          'Please, check your personal email and enter the verification code',
          status.ERROR,
          duration.LONG,
        );
      },
      onCompleted(data) {
        handleConfirmEmailData(data);
      },
    });

  const [updateUserEmail, { loading: updateLoading }] = useMutation(
    UPDATE_USER_EMAIL,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setEmailError(errorMessage || 'Unable to update your email.');
        hideAlert(setEmailError);
      },
      onCompleted({ updateUserEmail: { ok } }) {
        if (!ok) {
          setEmailError('Unable to update your email');
        } else {
          setSignupState(prevState => ({ ...prevState, email: newEmail }));
          setAlertSuccess('Email successfully changed');
          hideAlert(setAlertSuccess);
          resendEmailOTP();
        }
      },
    },
  );

  const handleConfirmEmailData = useCallback(({ confirmPersonalEmail }) => {
    const { ok } = confirmPersonalEmail;
    if (!ok) {
      notify.show(
        'Please, check your personal email and enter the verification code',
        status.ERROR,
        duration.LONG,
      );
    } else {
      updateAuthType();
      history.push(`/application/${NEW_LOAN_ROUTES.newApplication}`);
    }
  }, []);

  const handleSubmit = useCallback(
    ({ workEmailVerificationCode }) => {
      logEvent('Signup', 'Confirm your personal email');
      confirmPersonalEmail({ variables: { code: workEmailVerificationCode } });
    },
    [signupState],
  );

  const openEditButtonPopup = () => {
    setEditEmailPopup(true);
  };

  const closeEditButtonPopup = () => {
    setEditEmailPopup(false);
  };

  const resendEmailOTP = useCallback(async () => {
    logEvent('Signup', 'Resend Personal Email OTP');
    triggerEmailConfirmation();
    closeEditButtonPopup();
  }, [signupState]);

  const handleEmailEdit = useCallback(
    ({ email }) => {
      setNewEmail(email);
      closeEditButtonPopup();
      updateUserEmail({ variables: { userId: user.id, email } });
    },
    [signupState],
  );

  useEffect(() => {
    updateContextState('verify-email');
  }, []);

  useEffect(() => {
    switch (true) {
      case !user?.id:
        history.push('/sign-up/create-account');
        break;
    }
  }, [user]);

  return (
    <S.Wrapper>
      <DataCard
        loading={signupLoading || updateLoading || confirmPersonalEmailLoading}
      >
        {editEmailPopup && (
          <Popup title="Edit Personal Email" classes="edit-phone">
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={EditEmailValidationSchema}
              onSubmit={values => {
                handleEmailEdit(values);
              }}
            >
              <Form>
                <Input name="email" placeholder="Personal Email" type="email" />
                <SubmitButton value="Save Email" />
                <Button
                  classes="secondary block"
                  click_event={closeEditButtonPopup}
                >
                  Cancel
                </Button>
              </Form>
            </Formik>
          </Popup>
        )}
        <S.Title>Verify Personal Email</S.Title>
        <S.Subtitle>{`We sent you a verification code to ${
          email || ''
        }. Please enter the 6-digit OTP below.`}</S.Subtitle>
        {(signupError || emailError) && (
          <Alert classes="error">{signupError || emailError}</Alert>
        )}
        {alertSuccess && <Alert classes="success">{alertSuccess}</Alert>}
        <Formik
          initialValues={{
            workEmailVerificationCode: '',
          }}
          validationSchema={EmailOTPValidationSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <Form>
            <NumberInput
              name="workEmailVerificationCode"
              maxLength="6"
              placeholder="Verification Code"
            />
            <S.Paragraph className="center-text">
              Didn't get OTP? <br />
              <button type="button" onClick={resendEmailOTP}>
                Resend OTP
              </button>{' '}
              /{' '}
              <button type="button" onClick={openEditButtonPopup}>
                {' '}
                Edit Email
              </button>
            </S.Paragraph>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default PhoneNumberVerification;
