import gql from 'graphql-tag';

export const CONSENT_PROVIDERS = gql`
  query {
    consentProviders {
      name
      postSelectionMessage
    }
  }
`;

export const REQUEST_CONSENT = gql`
  mutation RequestConsentMutation(
    $providerName: String!
    $applicationNumber: String!
  ) {
    requestConsent(
      input: {
        providerName: $providerName
        applicationNumber: $applicationNumber
      }
    ) {
      success
    }
  }
`;

export const CONSENT_CALLBACK = gql`
  mutation ConsentCallbackMutation(
    $applicationNumber: String!
    $providerName: String!
    $otp: Int!
  ) {
    consentCallback(
      input: {
        providerName: $providerName
        data: { applicationNumber: $applicationNumber, otp: $otp }
      }
    ) {
      success
      message
    }
  }
`;

export const DECLINE_OFFER_LETTER = gql`
  mutation DeclineOfferLetterMutation($applicationNumber: String!) {
    dissent(applicationNumber: $applicationNumber) {
      success
    }
  }
`;
