import React, { useEffect } from 'react';
import {
  BankStatementUpload,
  ConfirmRequest,
  Mbs,
} from '../../LoanApplication';
import { DataCard } from '../../../components';
import { Switch, useRouteMatch, useHistory } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { useCustomForm } from './useCustomForm';
import { ApplicationProvider } from '../ApplicationContext';
import './_CustomApplication.scss';
import MbsOtp from '../../LoanApplication/Mbs/components/MbsOtp';
import MbsInstruction from '../../LoanApplication/Mbs/components/MbsInstruction';
import { NEW_LOAN_ROUTES } from '../../LoanApplication/routes';
import CustomFormApplicationRoute from './CustomFormApplicationRoute';
import FormBuilder from './FormBuilder';

const CustomApplicationRouter = () => {
  const {
    formIndex,
    applicationForm,
    handleSubmit,
    defaultFormValues,
    saveFormLoading,
    getLatestLoading,
    loading,
    mutate,
    customPath,
    uploadDocumentLoading,
    uploadImageLoading,
  } = useCustomForm();

  const { path } = useRouteMatch();
  const getPath = route => `${path}/${route}`;
  const history = useHistory();
  const {
    params: { applicationNumber },
  } = useRouteMatch();

  useEffect(() => {
    if (Array.isArray(applicationForm) && applicationForm?.length > 0) {
      if (formIndex <= applicationForm?.length - 1) {
        if (customPath) {
          history.push(customPath);
        } else {
          history.push(
            `/application-custom/${applicationNumber}/${applicationForm[formIndex]?.name}`,
          );
        }
      } else {
        history.push(`/application-custom/${applicationNumber}/breakdown`);
      }
    }
  }, [formIndex, applicationForm, customPath]);

  return (
    <DataCard
      loading={
        getLatestLoading ||
        saveFormLoading ||
        loading ||
        uploadDocumentLoading ||
        uploadImageLoading
      }
    >
      {Array.isArray(applicationForm) && applicationForm.length > 0 && (
        <Switch>
          <CustomFormApplicationRoute path={getPath(NEW_LOAN_ROUTES.mbs)} exact>
            <Mbs customForm={true} handleNext={mutate} formIndex={formIndex} />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsOtp)}
            exact
          >
            <MbsOtp
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.mbsInstruction)}
            exact
          >
            <MbsInstruction
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.confirmLoan)}
            exact
          >
            <ConfirmRequest />
          </CustomFormApplicationRoute>
          <CustomFormApplicationRoute
            path={getPath(NEW_LOAN_ROUTES.uploadBankStatement)}
            exact
          >
            <BankStatementUpload
              customForm={true}
              handleNext={mutate}
              formIndex={formIndex}
            />
          </CustomFormApplicationRoute>
          {formIndex <= applicationForm?.length - 1 && (
            <CustomFormApplicationRoute path={`${path}/`}>
              <FormBuilder
                applicationForm={applicationForm}
                handleSubmit={handleSubmit}
                formIndex={formIndex}
                handleNext={mutate}
                defaultFormValues={defaultFormValues}
              />
            </CustomFormApplicationRoute>
          )}
        </Switch>
      )}
    </DataCard>
  );
};

const CustomApplication = () => {
  return (
    <ApplicationProvider>
      <section className="container application form-builder">
        <CustomApplicationRouter />
      </section>
    </ApplicationProvider>
  );
};

export default CustomApplication;
