import { useLoanApplicationContext } from '../../../hooks';
import { handleGraphQLErrors } from '../../../lib/utils';
import { useMutation } from '@apollo/client';
import {
  COMPLETE_APPLICATION,
  GENERATE_APPLICATION_OFFERS,
} from '../mutations';
import { useEffect } from 'react';
import { pages } from '../../../lib/constants';

const useConfirmRequest = () => {
  const {
    application,
    applicationLoading,
    createApplicationTrace,
    showErrorAlert,
    showSuccessAlert,
    goToLoanDetailsPage,
  } = useLoanApplicationContext();

  const applicationId = application?.application?.id;

  const [callGenerateApplicationOffers, { loading: offerLoading, data }] =
    useMutation(GENERATE_APPLICATION_OFFERS, {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) ||
            'An error occurred during your application.',
        );
        onComplete();
      },
      onCompleted({ generateApplicationOffers }) {
        if (!generateApplicationOffers) {
          showErrorAlert(
            'There was an error processing your loan. Please try again later.',
          );
          onComplete();
          return;
        }

        const { suggestedOffer } = generateApplicationOffers;

        if (!suggestedOffer) {
          showSuccessAlert('Application is being processed.');
          onComplete();
        }
      },
    });

  const [runCompleteApplication, { loading: completeApplicationLoading }] =
    useMutation(COMPLETE_APPLICATION, {
      onError(error) {
        showErrorAlert(
          handleGraphQLErrors(error) ||
            'An error occurred during your application.',
        );
        goToLoanDetailsPage();
      },
      onCompleted({ completeApplication }) {
        if (!completeApplication) {
          showErrorAlert(
            'There was an error processing your loan. Please try again later.',
          );
        } else {
          showSuccessAlert('Your application has been submitted.');
        }

        goToLoanDetailsPage();
      },
    });

  const onComplete = () => {
    runCompleteApplication({
      variables: {
        applicationId,
      },
    });
  };

  useEffect(() => {
    if (applicationId) {
      callGenerateApplicationOffers({
        variables: {
          applicationId,
        },
      });
      createApplicationTrace(
        pages.confirmRequest,
        'Navigated to Request Confirmation Screen',
      );
    }
  }, [applicationId]);

  return {
    applicationLoading,
    data,
    offerLoading,
    onComplete,
    completeApplicationLoading,
    application,
  };
};

export default useConfirmRequest;
