import React, { useEffect, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { DataCard, Alert, Popup, Button } from '../../../components';
import { NumberInput, SubmitButton } from '../../../components/FormikElements';
import { useSignupContext } from '../../../hooks';
import { logEvent } from '../../../lib/GAHelper';
import { handleGraphQLErrors } from '../../../lib/utils';
import {
  OTPValidationSchema,
  EditPhoneNumberValidationSchema,
} from '../../../lib/validationSchemas';
import {
  TRIGGER_PHONE_CONFIRMATION,
  CONFIRM_PHONE,
  UPDATE_PHONE_NUMBER,
} from '../mutations';

const hideAlert = state => {
  setTimeout(() => {
    state(false);
  }, 5000);
};

const PhoneNumberVerification = ({ history }) => {
  const {
    signupState,
    setSignupState,
    verifyAge,
    customerRemita,
    updateContextState,
    clientInfo: { useRemita },
  } = useSignupContext();

  const [phoneError, setPhoneError] = useState(null);
  const [editPhoneNumberPopup, setEditPhoneNumberPopup] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const { user, phoneNumber: userPhoneNumber, contextId } = signupState;

  const [confirmPhone, { loading: confirmPhoneLoading }] = useMutation(
    CONFIRM_PHONE,
    {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setPhoneError(errorMessage || 'Unable to verify phone number.');
      },
      onCompleted(data) {
        handleConfirmPhoneData(data);
      },
    },
  );

  const [triggerPhoneConfirmation, { loading: phoneConfirmationLoading }] =
    useMutation(TRIGGER_PHONE_CONFIRMATION, {
      onError() {
        const errorMessage =
          'An error occurred. Please contact your support team for more information.';
        setPhoneError(
          errorMessage || 'Unable to resend OTP. Please, try again later.',
        );
        hideAlert(setPhoneError);
      },
      onCompleted(data) {
        handleTriggerPhoneConfirmationData(data);
      },
    });

  const [updatePhoneNumber, { loading: updatePhoneNumberLoading }] =
    useMutation(UPDATE_PHONE_NUMBER, {
      onError(error) {
        const errorMessage = handleGraphQLErrors(error);
        setPhoneError(errorMessage || 'Unable to Resend OTP');
        hideAlert(setPhoneError);
        setEditPhoneNumberPopup(false);
      },
      onCompleted(data) {
        handleUpdatePhoneNumberData(data);
      },
    });

  const getBvnStatus = useCallback(() => {
    logEvent('Signup', 'Confirm user BVN Status', true);
    if (!!user.bvnStatus) {
      const { dateOfBirth, isMatchingPhone, isVerified } = user.bvnStatus;
      setSignupState(prevState => ({
        ...prevState,
        bvnStatus: { dateOfBirth },
      }));

      if (isMatchingPhone) {
        verifyAge();
      }
      if (isVerified) {
        verifyAge();
      } else {
        history.push('/sign-up/verify-bvn-phone');
      }
    } else {
      history.push('/sign-up/verify-email');
    }
  }, [signupState]);

  const handleSubmit = useCallback(
    async values => {
      logEvent('Signup', 'Confirm user phone number');
      confirmPhone({ variables: { code: values.otp, userId: user.id } });
    },
    [user],
  );

  const openEditButtonPopup = () => {
    setEditPhoneNumberPopup(true);
  };

  const closeEditButtonPopup = () => {
    setEditPhoneNumberPopup(false);
  };

  const resendOTP = useCallback(async () => {
    logEvent('Signup', 'Resent OTP');
    triggerPhoneConfirmation({ variables: { userId: user.id } });
  }, [signupState]);

  const handlePhoneNumberEdit = useCallback(
    async values => {
      logEvent('Signup', 'Edit Phone Number');
      closeEditButtonPopup();
      updatePhoneNumber({
        variables: { userId: user.id, phone: values.phoneNumber },
      });
      setSignupState(prevState => ({
        ...prevState,
        phoneNumber: values.phoneNumber,
      }));
    },
    [signupState],
  );

  const handleTriggerPhoneConfirmationData = useCallback(
    ({ triggerPhoneConfirmation }) => {
      const { ok } = triggerPhoneConfirmation;
      if (!ok) setPhoneError('Unable to send OTP. Please, try again later.');
      else {
        setAlertSuccess('OTP sent successfully');
        hideAlert(setAlertSuccess);
      }
    },
    [signupState],
  );

  const handleUpdatePhoneNumberData = useCallback(
    ({ updatePhoneNumber }) => {
      const { ok } = updatePhoneNumber;
      if (!ok) {
        setPhoneError('Unable to send OTP');
        hideAlert(setPhoneError);
        setEditPhoneNumberPopup(false);
      } else {
        setEditPhoneNumberPopup(false);
        setAlertSuccess('Phone number successfully changed');
        hideAlert(setAlertSuccess);
        resendOTP();
      }
    },
    [signupState],
  );

  const handleConfirmPhoneData = useCallback(
    ({ confirmPhone }) => {
      const { ok } = confirmPhone;
      if (!ok) setPhoneError('Unable to verify phone number.');
      else {
        getBvnStatus();
      }
    },
    [signupState],
  );

  useEffect(() => {
    if (useRemita) {
      customerRemita();
    }
  }, [signupState]);

  useEffect(() => {
    if (contextId) {
      updateContextState('verify-phone');
    }
  }, [contextId]);

  useEffect(() => {
    switch (true) {
      case !user?.id:
        history.push('/sign-up/create-account');
        break;
    }
  }, [user]);

  return (
    <S.Wrapper>
      <DataCard
        loading={
          confirmPhoneLoading ||
          phoneConfirmationLoading ||
          updatePhoneNumberLoading
        }
      >
        {editPhoneNumberPopup && (
          <Popup title="Edit phone number" classes="edit-phone">
            <Formik
              initialValues={{
                phoneNumber: '',
              }}
              validationSchema={EditPhoneNumberValidationSchema}
              onSubmit={values => {
                handlePhoneNumberEdit(values);
              }}
            >
              <Form>
                <NumberInput
                  placeholder="08012345678"
                  phoneField
                  name="phoneNumber"
                  maxLength="11"
                />

                <SubmitButton value="Save Number" />
                <Button
                  classes="secondary block"
                  click_event={closeEditButtonPopup}
                >
                  Cancel
                </Button>
              </Form>
            </Formik>
          </Popup>
        )}
        <S.Title>Phone Number Verification</S.Title>
        {phoneError && <Alert classes="error">{phoneError}</Alert>}
        {alertSuccess && <Alert classes="success">{alertSuccess}</Alert>}
        <S.Subtitle>
          We sent a verification code to <b>{userPhoneNumber}</b> via <b>SMS</b>{' '}
          and <b>Whatsapp.</b> Please enter the 6-digit OTP below.
        </S.Subtitle>
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={OTPValidationSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <Form>
            <NumberInput
              name="otp"
              maxLength="6"
              placeholder="Verification Code"
            />

            <S.Paragraph className="center-text">
              Didn't get OTP? <br />
              <button type="button" onClick={resendOTP}>
                Resend OTP
              </button>{' '}
              /{' '}
              <button type="button" onClick={openEditButtonPopup}>
                {' '}
                Edit Phone Number
              </button>
            </S.Paragraph>
            <S.Paragraph className="center-text bold-text">
              NOTE: The OTP may not deliver if DND is active on your number.
              Send ALLOW to 2442 to remove DND, then click
              <button type="button" onClick={resendOTP}>
                Resend OTP
              </button>
            </S.Paragraph>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default PhoneNumberVerification;
