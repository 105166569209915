import * as S from '../styled';
import React from 'react';
import useMbsForm from '../useMbsForm';
import { DataCard } from '../../../../components';

const MbsInstruction = ({ customForm, handleNext, formIndex }) => {
  const {
    bankName,
    clientName,
    customMbsDates,
    handleExternalSubmit,
    handleSkip,
    loading,
    applicationLoading,
  } = useMbsForm({ customForm, handleNext, formIndex });

  return (
    <S.Wrapper>
      <DataCard loading={loading || applicationLoading}>
        <S.Title>
          Please follow the instructions below to retrieve your bank statement
        </S.Title>
        <S.Subtitle>
          Your bank may charge you separately for this statement
        </S.Subtitle>
        <S.List>
          <li>{`Log in to ${bankName} internet banking on your computer or your smartphone`}</li>
          <li>
            Check the Sidebar for “Send Statement to Third party" or "Statement
            Request"
          </li>
          <li>{`For Destination, please select ${clientName}.`}</li>
          <li>For Role, please select Applicant.</li>
          <li>{`For Start Date, please select ${customMbsDates.startDate}`}</li>
          <li>{`For End Date, please select ${customMbsDates.endDate}`}</li>
          <li>For Applicant(s), please enter your Full Name</li>
          <li>Complete the rest of the form and submit.</li>
        </S.List>
        <S.FooterText>
          Click Continue button below when you receive your ticket number and
          password via SMS
        </S.FooterText>
        <S.Wrapper>
          <S.Button type="submit" value="Next" onClick={handleExternalSubmit} />
        </S.Wrapper>
        <S.SkipButton
          type="button"
          onClick={handleSkip}
          value="Skip, I don't have mobile/internet banking"
        />
      </DataCard>
    </S.Wrapper>
  );
};

export default MbsInstruction;
