import React from 'react';
import { Formik, Form } from 'formik';
import * as S from './styled';
import { DataCard } from '../../../components';
import {
  Input,
  NumberInput,
  SubmitButton,
} from '../../../components/FormikElements';
import { refereeDetailsSchema } from '../../../lib/validationSchemas';
import useRefereeDetailsForm from './useRefereeDetailsForm';

const RefereeDetailsScreen = () => {
  const {
    applicationLoading,
    loanApplicationIsLoading,
    currentReferee,
    addReferee,
    userLoading,
  } = useRefereeDetailsForm();

  return (
    <S.Wrapper>
      <DataCard
        loading={applicationLoading || userLoading || loanApplicationIsLoading}
      >
        <S.Title>Guarantor Information</S.Title>
        <S.Subtitle>Please, enter the details of your Guarantor</S.Subtitle>
        <Formik
          initialValues={{
            refereeName: currentReferee?.refereeName,
            refereePhone: currentReferee?.refereePhone,
            refereeWorkPlace: currentReferee?.refereeWorkPlace,
            refereeEmail: currentReferee?.refereeEmail,
          }}
          validationSchema={refereeDetailsSchema}
          onSubmit={values => {
            addReferee(values);
          }}
        >
          <Form>
            <Input name="refereeName" placeholder="Guarantor Name" />
            <NumberInput
              name="refereePhone"
              placeholder="08012345678"
              maxLength="11"
              phoneField
            />
            <Input name="refereeWorkPlace" placeholder="Guarantor Address" />
            <Input
              name="refereeEmail"
              placeholder="Guarantor Email (Optional)"
              type="email"
            />
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default RefereeDetailsScreen;
