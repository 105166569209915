const graphQlRequest = require('../lib/requestClient');

const USER_FRAGMENT = `
     me {
        id
        firstName
        lastName
        email
        phone
        status
        userMetadata{
          id
          name
          value
        }
        isEmailConfirmed
        isPhoneConfirmed
        isWorkEmailConfirmed
        bvnStatus {
            bvn
            dateOfBirth
            isVerified
            isMatchingPhone
        }
    }
`;

const PORTFOLIO_FRAGMENT = `
    portfolios {
        nodes {
          id
          amount
          chargesAmount
          taxAmount
          status {
            name
          }
          createdAt
          fullAmount
          dateOfRepayment
          amountPaid
          amountDisbursed
        }
      }`;

const APPLICATION_FRAGMENT = `
    applications {
        nodes {
          amount
          chargesAmount
          taxAmount
          status {
            name
          }
          createdAt
          fullAmount
          dateOfRepayment
        }
      }
`;

const BANK_ACCOUNT_FRAGMENT = `
      bankAccounts {
        id
        accountName
        accountNumber
        isDefault
        status
        okraRecord {
          id
          directDebitAuthorised
        }
      }
`;

const ACCOUNT_FRAGMENT = `
    account {
      id
      name
      ${BANK_ACCOUNT_FRAGMENT}
      ${APPLICATION_FRAGMENT}
      ${PORTFOLIO_FRAGMENT}
    }
`;

export default class UserService {
  /**
   *
   * @return {*}
   */
  static viewer() {
    const query = `{                
            viewer {                    
                    id                         
                    ${USER_FRAGMENT}
                    ${ACCOUNT_FRAGMENT}
                 }            
            }`;

    return graphQlRequest()
      .post('graphql', { query })
      .catch(() => {});
  }
}
