import gql from 'graphql-tag';

export const CLIENT_INFO_QUERY = gql`
  query ClientInfo($slug: String, $clientUrl: String, $loanCategoryId: ID) {
    clientInfo(
      input: {
        slug: $slug
        clientAppUrl: $clientUrl
        loanCategoryId: $loanCategoryId
      }
    ) {
      name
      slug
      requiresCrcReport
      requiresCrcMicroReport
      logoUrl
      bvnServiceProviders {
        enabled
        name
      }
      clientId
      addCardCharge
      addCardCharges {
        amount
      }
      maxLoanAmount
      minLoanAmount
      termsAndConditionsUrl
      privacyPolicyUrl
      requiresReferee
      noCardImplementation
      requiresGovernmentId
      requiresFacebookUrl
      clientAppUrl
      paystackPubKey
      facebookAppKeys {
        appId
      }
      allowedGeoLocationBoundaries {
        radius
        lon
        lat
      }
      faviconUrl
      loanMonthlyDurations {
        duration
        durationType
      }
      requiredSupportingDocuments {
        description
        documentName
      }
      requireWorkEmail
      requiresBankStatement
      requiresWorkIDUpload
      requiresBankStatementUpload
      requiresOnePipeLookUp
      addCardCharges {
        amount
      }
      useRemita
      okraWidgetUrl
      requiresOkraTransactions
      requiresSelfieUpload
      requiresRemitaMandate
      useLoanGroups
      contactDetails {
        phone
        email
      }
      bankDetails {
        accountName
        accountNumber
        isDefault
        bank {
          name
        }
      }
      clientTheme {
        primaryColor
        secondaryColor
      }
      requiredSocialMediaAccounts {
        name
      }
      mbsCbChargeAmount
      okraDirectDebitIsEnabled
      repaymentServices {
        name
      }
    }
  }
`;

export const GET_ENUM = gql`
  query getEnum($type: Enum!) {
    enumValues(type: $type) {
      name
    }
  }
`;

export const GET_CUSTOMER_REMITA_STATUS = gql`
  query CustomerRemitaStatus($clientId: ID, $customerPhoneNumber: String) {
    customerRemitaStatus(
      input: { clientId: $clientId, customerPhoneNumber: $customerPhoneNumber }
    ) {
      bankId
      companyName
      customerName
      accountNumber
      hasRemitaData
      salaryPaymentDetails {
        amount
        paymentDate
      }
    }
  }
`;
