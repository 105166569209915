import React from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import CreateNewAccount from '../CreateNewAccount';
import PhoneNumberVerification from '../PhoneNumberVerification';
import PersonalEmailVerification from '../PersonalEmailVerification';
import SignupRoute from '../SignupRoute';
import { SignupProvider } from '../SignupContext';
import * as S from './styled';

const Signup = ({ locationState, location }) => {
  const { path } = useRouteMatch();
  return (
    <SignupProvider>
      <Notifications />
      <S.Progressbar pathname={location.pathname}>
        <div></div>
      </S.Progressbar>
      <Switch>
        <SignupRoute
          path={`${path}/create-account`}
          component={CreateNewAccount}
        />
        <SignupRoute
          path={`${path}/verify-phone`}
          component={PhoneNumberVerification}
        />
        <SignupRoute
          path={`${path}/verify-email`}
          component={PersonalEmailVerification}
        />
        <Redirect
          to={{
            pathname: `${path}/create-account`,
            state: locationState,
          }}
        />
      </Switch>
    </SignupProvider>
  );
};

export default Signup;
