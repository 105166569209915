import React from 'react';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import { Formik, Form } from 'formik';
import { SubmitButton, Input } from '../../../components/FormikElements';
import useBankStatementUploaderForm from './useBankStatementUploaderForm';

const BankStatementUpload = ({ customForm, handleNext, formIndex }) => {
  const {
    applicationLoading,
    uploadBankStatementLoading,
    uploadBankStatementError,
    handleUpload,
    handleDocumentPreview,
    bankStatementDetails,
  } = useBankStatementUploaderForm({ customForm, handleNext, formIndex });
  return (
    <S.Wrapper>
      <DataCard loading={applicationLoading || uploadBankStatementLoading}>
        <S.Title>Upload your bank statement</S.Title>
        <S.Subtitle>
          Please, upload your bank statement for the last 3 months
        </S.Subtitle>
        {uploadBankStatementError && (
          <Alert classes="error">{uploadBankStatementError}</Alert>
        )}
        <Formik
          initialValues={{
            statementPassword: '',
          }}
          onSubmit={values => handleUpload(values)}
        >
          <Form>
            <S.ImageUpload>
              <input
                name="bankStatement"
                type="file"
                accept="application/pdf"
                onChange={handleDocumentPreview}
              />
              <section>
                <div>
                  <span>
                    {bankStatementDetails.fileName ||
                      'Click here to upload file (PDF)'}
                  </span>
                  <p> {bankStatementDetails.fileSize || ''}</p>
                </div>
              </section>
            </S.ImageUpload>
            <S.Aside>
              {' '}
              Upload PDF files and File size should not exceed 1MB
            </S.Aside>
            <Input
              name="statementPassword"
              type="password"
              placeholder="Password"
            />
            <S.Aside>Please enter document password (if any)</S.Aside>
            <SubmitButton value="Next" />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default BankStatementUpload;
