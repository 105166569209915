import styled from 'styled-components';
import { Title as Tit } from '../../styled';
export { Wrapper, Title, Paragraph, PreviousButton } from '../../styled';

export const Subtitle = styled(Tit)`
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.fonts.font_regular};
`;

export const LoanCategoryWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Detail = styled.label`
  border: 1px solid #a4a59a;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: row;
  padding: 7px 12px;
  transition: 0.3s;
  width: 100%;

  &.inactive {
    display: none;
  }
`;

export const CategoryDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RadioTitle = styled.div`
  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: space-between;
  line-height: 2rem;
  width: 100%;
`;

export const RadioDescription = styled.div`
  li {
    list-style: none;
    line-height: 20px;
  }
`;

export const Error = styled.p`
  color: #ae4036;
  font-size: 12px;
  margin: 0;
`;

export const RadioInputWrapper = styled.section`
  align-items: center;
  position: relative;
  display: flex;
`;

export const RadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${({ theme }) => theme.secondaryColor};
    border: 0;

    :after {
      background: white;
    }
  }
`;

export const Span = styled.span`
  position: relative;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #a4a59a;
  transition: 0.3s;

  :after {
    content: '';
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: transparent;
    transition: 0.3s;
  }
`;

export const LinkButton = styled.button`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 1.3rem;
  padding: 0;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.font_semibold};
  margin: 8px 0 0;
  text-align: center;

  &:hover {
    text-decoration: none;
  }
`;
