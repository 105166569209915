import React from 'react';
import * as S from './styled';
import { DataCard, Alert } from '../../../components';
import { Formik, Form } from 'formik';
import { SubmitButton } from '../../../components/FormikElements';
import WorkIdPlaceholder from '../../../static/images/workid-placeholder.jpg';
import useWorkIdUploadForm from './useWorkIdUploadForm';

const WorkIdUploadScreen = () => {
  const {
    applicationLoading,
    uploadImageError,
    uploadImageLoading,
    workIdDetails,
    handleUpload,
    handlePreview,
    goToPreviousPage,
  } = useWorkIdUploadForm();

  return (
    <S.Wrapper>
      <DataCard loading={uploadImageLoading || applicationLoading}>
        <S.Title>Upload your Work ID Card</S.Title>
        {uploadImageError && <Alert classes="error">{uploadImageError}</Alert>}
        <Formik initialValues={{}} onSubmit={() => handleUpload()}>
          <Form>
            <S.ImageUpload>
              <input
                name="workid"
                type="file"
                accept="image/*, .heic"
                onChange={handlePreview}
              />
              <section>
                <img
                  src={workIdDetails.url || WorkIdPlaceholder}
                  alt="workid"
                />
              </section>
            </S.ImageUpload>
            <SubmitButton value="Next" />
            <S.PreviousButton
              type="button"
              onClick={goToPreviousPage}
              value="Previous"
            />
          </Form>
        </Formik>
      </DataCard>
    </S.Wrapper>
  );
};

export default WorkIdUploadScreen;
